import { VaultService } from '@/core/services/vault.service';
import CommentsPanel from '@/modules/campaigns/components/CommentsPanel'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ConciliationItems } from '../models/conciliation.model';
import { useConciliationStore } from '../stores/conciliation.store';
import { Comment } from '@/modules/campaigns/models/campaign.model';
import BrandPageHead from '@/core/components/molecules/BrandPageHead';
import { useComponentsStore } from '@/core/stores/components.store';
import PurchaseOrdersHeadVersionsStatus from './PurchaseOrdersHeadVersionsStatus';

function PurchaseOrderDocumentView() {
  const vaultService = new VaultService();
  const { selectedConciliation, updatePuchaseOrderComments, selectedPurchaseOrder } = useConciliationStore()
  const uiConciliationComponents = useComponentsStore(e => e.uiConciliationComponents)
  const [selectedOrder, setSelectedOrder] = useState<string>('')
  const [pdfSrc, setPdfSrc] = useState<string | null>(null);

  const handleComments = async (comments: Comment[]) => {
    const newItem = selectedConciliation?.purchaseOrderList?.map(e => e.fileId === selectedPurchaseOrder?.fileId ? {
      ...selectedPurchaseOrder,
      comments: comments
    } : e)
    let body: ConciliationItems = {
      ...selectedConciliation,
      purchaseOrderList: newItem
    }
    updatePuchaseOrderComments(body).then(
      response => {
        console.log(response)
      }
    )
  }

  useEffect(() => {
    if (selectedPurchaseOrder) {
      setSelectedOrder(selectedPurchaseOrder.fileId)
      vaultService.getFile(selectedPurchaseOrder.fileId).then(res => {
        //@ts-ignore
        setPdfSrc(res.file)
      }).catch((error) => {
        console.error(error)
      })
    }
  }, [selectedPurchaseOrder])

  return (
    <>
      {/* <BrandPageHead subtitle='Órden de compra' backButton={uiConciliationComponents.seeOrdersProviders}>
        <Flex gap={2} alignItems={'center'}>
          <Text fontWeight="bold">Campaña:</Text>
          <Text>{selectedConciliation?.name}</Text>
        </Flex>
      </BrandPageHead> */}
      <PurchaseOrdersHeadVersionsStatus selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />
      <Flex>
        <Box w={'70%'}>
          <object type="application/pdf" data={`data:application/pdf;base64,${pdfSrc}`} width="100%" height="100%">
            <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={`data:application/pdf;base64,${pdfSrc}`}>Descargar PDF</a>.</p>
          </object>
        </Box>
        <CommentsPanel comments={selectedPurchaseOrder?.comments} sendComments={handleComments} />
      </Flex>
    </>
  )
}

export default PurchaseOrderDocumentView