import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  TabList,
  Box,
  Text,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";

interface ChatListProps {
  historyChats: Array<{ id: string; ask: string }>;
  loadMessages: (chatId: string) => void;
  newChat: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ChatList = ({ historyChats = [], loadMessages, newChat, isOpen, onClose }: ChatListProps) => {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader display="flex" justifyContent="space-between">
          <Box textAlign="center" width="100%">
            Historial
          </Box>
          <Tooltip label="Nuevo chat" hasArrow>
            <Box as="span" cursor="pointer" onClick={newChat}>
              <Icon as={BiPlus} w={6} h={6} />
            </Box>
          </Tooltip>
        </DrawerHeader>

        <DrawerBody overflowY="auto" display="flex" flexDirection="column">
          <TabList overflowY="auto">
            {historyChats.map((chat) => (
              <Box
                key={chat.id}
                p="3"
                mb="2"
                bg="blue.50"
                borderRadius="md"
                boxShadow="sm"
                cursor="pointer"
                onClick={() => loadMessages(chat.id)}
              >
                <Text noOfLines={1}>{chat.ask}</Text>
              </Box>
            ))}
          </TabList>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
