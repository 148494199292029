import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { useNavigationStore } from "@/core/stores/navigation.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useModalStore } from "@/core/stores/modal.store"
import { UtilService } from "@/core/services/util.service"
import { useExcelTableStore } from "@/core/stores/excel-table.store"
import { useCampaignStore } from "../store/campaign.store"
import { DatesUtil } from "@/core/utils/dates.util"

const SaveExcelFileAlertModal: React.FC = () => {
  const utilService = new UtilService()
  const toast = useCustomToast()
  const sectionId = useNavigationStore(e => e.selectedNavigation)
  const closeModal = useModalStore(s => s.closeModal)
  const { selectedCampaign, selectedFlowMedia, createFlowDocument, retrieveCampaignItems } = useCampaignStore()
  const { selectedFile } = useExcelTableStore();


  const handleExcelFile = () => {


    const formData = new FormData()

    formData.append("file", selectedFile as File)

    utilService.uploadFile(formData!).then(
      response => {
        if (response.codigo === 0) {
          handleSaveExcelFile(response.respuesta)
        }
      }
    ).catch(
      () => {
        toast.error('Ocurrió un error al subir el documento')
      }
    )
  }

  const handleSaveExcelFile = (fileId: string) => {
    const date = new Date()
    const body = {
      fileId: fileId,
      version: `Ver.${selectedFlowMedia?.fileVersionList?.length! + 1} ${DatesUtil.formatDateFiles(date!)}`
    }

    createFlowDocument(selectedCampaign?.id!, selectedFlowMedia?.id!, body).then(
      () => {
        toast.success('Documento guardado correctamente')
        retrieveCampaignItems(sectionId?.id!)
        closeModal('SaveExcelFileAlertModal')
      }
    ).catch(
      () => toast.error('Ocurrió un error al crear el Documento')
    )
  }

  return (
    <CustomAlertDialog
      id="SaveExcelFileAlertModal"
      title="Guardar cambios"
      cancelText="No"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleExcelFile}
    >
      {() => (
        <Box>
          <Text>¿Desea guardar los cambios realizados en el documento?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default SaveExcelFileAlertModal