import { Box, Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import SubSectionsModal from "@/modules/planning/components/SubSectionsModal"
import { useCampaignStore } from "../store/campaign.store"
import { useState } from "react"
import { useExcelTableStore } from "@/core/stores/excel-table.store"

const FlowTabs: React.FC = () => {
    const { selectedCampaign, setSelectedFlowMedia } = useCampaignStore()
    const [tabSelected, setSelectedTab] = useState<number>(0)
    const { reset } = useExcelTableStore()
    const campaignSubSection = [
        {
            name: "RESUMEN",
            type: "SUMMARY",
            enable: true
        }
    ].concat(
        ...selectedCampaign?.mediaList?.map(e => ({
            name: e.name,
            type: e.id,
            enable: true
        }))!
    )

    /** */
    const navigate = useNavigate()

    /* const tabIndex: { [key: string]: number } = {
        '/views/campaigns/detail/flow/summary': 0,
        ...(selectedCampaign?.mediaList?.reduce((acc: { [key: string]: number }, e, i) => {
            acc[`/views/campaigns/detail/flow/media`] = i + 1;
            return acc;
        }, {}))
    }; */

    const handleTabsChange = (index: number) => {
        const sel = selectedCampaign?.mediaList?.find(e => e.id === campaignSubSection[index].type)
        setSelectedFlowMedia(sel!)
        setSelectedTab(index)
        reset()
        if (index === 0) {
            navigate('/views/campaigns/detail/flow/summary')
        } else {
            navigate('/views/campaigns/detail/flow/media')
        }
    }

    return (
        <>
            <Box display={'flex'} mb={3}>
                <Tabs index={tabSelected}
                    variant='unstyled'
                    position='relative'
                    colorScheme="brand"
                    onChange={handleTabsChange}
                >
                    <TabList>
                        {campaignSubSection?.map((e) => (
                            e.enable &&
                            <Tab key={e.type} className="flex items-center gap-1">
                                <span>{e.name}</span>
                            </Tab>
                        ))}
                    </TabList>
                    <TabIndicator />
                </Tabs>
                {/* <Tooltip label="Gestionar sub secciones">
                    <Button className="ml-4"
                        as={IconButton}
                        icon={<TbSettings2 size={20} />}
                        variant="ghost"
                        size="sm"
                        onClick={() => openModal('SubSectionsModal')}
                    />
                </Tooltip> */}
            </Box>
            <SubSectionsModal />
        </>
    )
}

export default FlowTabs