import { format, parseISO } from "date-fns"
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es.json";

TimeAgo.addLocale(es)

export const timeAgo = new TimeAgo("es")

export class DatesUtil {

    public static formatDate(date: string, whitTimes = false): string {
        /** */
        if (!date) return "---"

        try {
            const parsedDate = new Date(date)

            return format(parsedDate, `dd/MM/yyyy${whitTimes ? " - hh:mm" : ""}`)
        } catch {
            return "---"
        }
    }

    public static handlePeriodMonth(date: string, month: number, year: number): boolean {
        const dateData = new Date(`${date}T00:00:00`)
        return (dateData.getMonth() + 1) === month && dateData.getFullYear() === year
      }

    public static formatDateFiles(date: string | Date, whitTimes = true): string {
        /** */
        if (!date) return "---"
        let parsedDate: string | Date = '';
        try {
            if (typeof date === 'string') {
                parsedDate = new Date(date)
            } else {
                parsedDate = date
            }

            return format(parsedDate, `yyMMdd${whitTimes ? "_HHmm" : ""}`)
        } catch {
            return "---"
        }
    }

    public static formatDateToString(date: Date | string): string {
        if (typeof date === 'string') {
            const parsedDate = new Date(date);
            return format(parsedDate, `yyyy-MM-dd`)
        }
        return format(date, `yyyy-MM-dd`)
    }

    public static formatStringToDate(date: string): Date {
        return parseISO(date);
    }

    public static getTimeAgo(date: string): string {
        /** */
        try {
            return timeAgo.format(new Date(date), "round")
        } catch {
            return ""
        }
    }

    public static getDifferenceInMilliseconds(beforeDate: Date, afterDate: Date): number {
        /** */
        return Math.abs(beforeDate.getTime() - afterDate.getTime())
    }

    public static getDateToComment(date: Date | string): string {
        if (!date) return "---"

        try {
            //const parsedDate = new Date(date)

            return format(date, `dd MMM yy / hh:mm`)
        } catch {
            return "---"
        }
    }

}