import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Campaign, CampaignGraphData, CampaignIndicators, CampaignItem, MediaList, Objetive, subSectionList, VersionList } from "../models/campaign.model";
import { CampaignStatus } from "../enums/campaign.enum";
import { PlanningService } from "@/modules/planning/services/planning.service";
import { Planning } from "@/core/models/planning.model";
import { useNavigationStore } from "@/core/stores/navigation.store";
import { CampaignService } from "../services/campaign.service";
import { VaultService } from "@/core/services/vault.service";
import { FilesUtil } from "@/core/utils/files.util";
import { useExcelTableStore } from "@/core/stores/excel-table.store";
import { BudgetOrdererDataProps } from "@/modules/planning/store/planning.store";
import { persist } from "zustand/middleware";
import { SecureSessionStorage } from "@/core/stores/secure-sessionstorage.store";

/** Services */
const planningService = new PlanningService()
const campaignService = new CampaignService()
const vaultService = new VaultService()

/** Contrato del Store */
interface CampaignState {
  campaignItemss: CampaignItem[]
  selectedCampaign?: CampaignItem
  tabSelected: string
  budgets: Planning[]
  selectedBudget?: Planning
  objetives: Objetive[]
  places: Objetive[]
  selectedFlowMedia?: MediaList
  campaignIndicator?: CampaignIndicators
  dashboardGraphicData?: CampaignGraphData[]
  retrieveCampaignItems: (sectionId: string) => Promise<boolean>
  setCampaignIndicator: (sectionId: string) => Promise<boolean>
  setGraphicData: (sectionId: string) => Promise<boolean>
  addCampaign: (item: CampaignItem) => Promise<boolean>
  updateCampaignItem: (item: Campaign) => Promise<boolean>
  updateCampaignBriefComments: (brief: CampaignItem) => Promise<boolean>
  updateCampaignStrategyComments: (strategy: CampaignItem) => Promise<boolean>
  updateCampaignSubSectionList: (campaignId: string, items: subSectionList) => Promise<boolean>
  updateStatusCampaign: (campaignId: string, status: CampaignStatus) => Promise<boolean>
  setSelectedCampaign: (item: CampaignItem) => void
  setSelectedBudget: (item: Planning) => void
  setSelectedFlowMedia: (item: MediaList) => void
  getBudgets: () => Promise<boolean>
  getObjetives: (companyId: string) => Promise<boolean>
  getPlaces: (companyId: string) => Promise<boolean>
  createFlowDocument: (campaignId: string, mediaId: string, fileData: VersionList) => Promise<boolean>
  getMediaFlowFile: (idFile: string, nameFile: string) => Promise<boolean>
  getSubSectionItemBy: (key: string) => subSectionList | undefined
  insertMediaListNodes: (campaignSectionId: string, mediaList: BudgetOrdererDataProps[]) => Promise<boolean>
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  selectedCampaign: undefined,
  tabSelected: '',
  budgets: [],
  objetives: [],
  places: [],
  campaignItemss: [],
  selectedBudget: undefined,
  selectedFlowMedia: undefined,
  campaignIndicator: undefined,
  dashboardGraphicData: undefined
}

/** Definición del Store */
const Store: StateCreator<CampaignState> = (set, get) => ({

  ...initialState,

  retrieveCampaignItems: (sectionId: string) => {
    return new Promise((resolve, reject) => {
      campaignService.getCampaignBySection(sectionId).then(
        response => {
          set({ campaignItemss: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  setCampaignIndicator: (sectionId: string) => {
    return new Promise((resolve, reject) => {
      campaignService.getCampaignBySection(sectionId).then(
        response => {
          const dataResponse: CampaignItem[] = response.respuesta
          const indicators = {
            activeCampaigns: dataResponse.filter(e => e.status === CampaignStatus.Actived).length,
            totalCampaigns: dataResponse.length
          }
          let data: CampaignGraphData[] = []
          dataResponse.forEach(e => {
            e.mediaList?.forEach(x => {
              if (x.amount !== 0) {
                data.push({
                  monto: x.amount, media: x.name
                })
              }
            })
          })
          const groupedArray: CampaignGraphData[] = Object.values(data.reduce((acc: any, item: any) => {
            if (!acc[item.media]) {
              acc[item.media] = { ...item }; // Si no existe, agrega el objeto
            } else {
              acc[item.media].monto += item.monto; // Si existe, suma el monto
            }
            return acc;
          }, {}));
          //let graph = dataResponse.map(e => e.mediaList?.map(e => ({ monto: e.amount, media: e.name })).filter(e => e.monto !== 0)).filter(e => e?.length !== 0)
          //console.log(groupedArray)
          set({ dashboardGraphicData: groupedArray })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  setGraphicData: (sectionId: string) => {
    return new Promise((resolve, reject) => {
      campaignService.getCampaignBySection(sectionId).then(
        response => {
          const dataResponse: CampaignItem[] = response.respuesta
          const indicators = {
            activeCampaigns: dataResponse.filter(e => e.status === CampaignStatus.Actived).length,
            totalCampaigns: dataResponse.length
          }
          set({ campaignIndicator: indicators })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  addCampaign: (item: CampaignItem) => {
    return new Promise((resolve, reject) => {
      campaignService.createCampaign(item).then(
        response => {
          if (response.codigo === 0) {
            const newItem = response.respuesta

            const items = get().campaignItemss

            items.push(newItem)
            set({ campaignItemss: items })
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch(() => reject())
      /* const items = get().campaignItemss
      items.push(item)

      set({ campaignItemss: items })
      resolve(true) */
    })
  },

  updateCampaignItem: (item: Campaign) => {
    return new Promise((resolve, reject) => {
    })
  },

  updateCampaignBriefComments: (brief: CampaignItem) => {
    return new Promise((resolve, reject) => {
      campaignService.updateCampaign(brief).then(
        response => {
          set({ selectedCampaign: response.respuesta })
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  updateCampaignStrategyComments: (strategy: CampaignItem) => {
    return new Promise((resolve, reject) => {
      campaignService.updateCampaign(strategy).then(
        response => {
          set({ selectedCampaign: response.respuesta })
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  getBudgets: () => {
    const planningSectionId = useNavigationStore.getState().navigationItems.filter((e) => e.sectionType === 'PLANNING')[0].id
    return new Promise((resolve, reject) => {
      planningService.getPlanningBySection(planningSectionId).then(
        response => {
          set({ budgets: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  getObjetives: (companyId: string) => {
    return new Promise((resolve, reject) => {
      campaignService.getCampaignObjetives(companyId).then(
        response => {
          set({ objetives: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  getPlaces: (companyId: string) => {
    return new Promise((resolve, reject) => {
      campaignService.getCampaignPlaces(companyId).then(
        response => {
          set({ places: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  getSubSectionItemBy: (key: string) => get().selectedCampaign?.subSectionList?.find(
    item => item.type === key
  ),

  createFlowDocument: (campaignId: string, mediaId: string, fileData: VersionList) => {
    return new Promise((resolve, reject) => {
      campaignService.createCampaignFlowFile(campaignId, mediaId, fileData).then(
        response => {
          if (response.codigo === 0) {
            const updatedItem: CampaignItem = response.respuesta
            const items = get().campaignItemss

            items.map(item => {
              if (updatedItem.id === item.id) {
                item = updatedItem
              }

              return item
            })

            set({ campaignItemss: items, selectedCampaign: updatedItem, selectedFlowMedia: updatedItem.mediaList?.find(e => e.id === mediaId) })

            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch(() => reject())
    })
  },

  updateCampaignSubSectionList: (campaignSectionId: string, items: subSectionList) => {
    return new Promise((resolve, reject) => {
      campaignService.updateCampaignSubSection(campaignSectionId, items).then(
        response => {
          if (response.codigo === 0) {
            const updatedItem: CampaignItem = response.respuesta
            const items = get().campaignItemss

            items.map(item => {
              if (updatedItem.id === item.id) {
                item = updatedItem
              }

              return item
            })

            set({ campaignItemss: items, selectedCampaign: updatedItem })
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch(() => reject())
    })
  },

  updateStatusCampaign: (idCampaign: string, status: CampaignStatus) => {
    return new Promise((resolve, reject) => {
      campaignService.updateCampaignStatus(idCampaign, status).then(
        response => {
          if (response.codigo === 0) {
            const updatedItem: CampaignItem = response.respuesta
            const items = get().campaignItemss

            items.map(item => {
              if (updatedItem.id === item.id) {
                item = updatedItem
              }

              return item
            })

            set({ campaignItemss: items, selectedCampaign: updatedItem })
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  getMediaFlowFile: (idFile: string, nameFile: string) => {
    return new Promise((resolve, reject) => {
      vaultService.getFile(idFile).then(
        response => {
          if (!!response) {
            //@ts-ignore
            const extension = response.name.split('.')[1];
            const typeFile = FilesUtil.getMimeType(extension);
            //@ts-ignore
            const fileBlob: Blob = FilesUtil.b64toBlob(response.file, typeFile);
            const file = new File([fileBlob], `${nameFile}.${extension}`, { type: typeFile })
            //@ts-ignore
            useExcelTableStore.getState().setSelectedFileTable(file!);
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch((e) => reject())
    })
  },

  insertMediaListNodes: (campaignSectionId: string, mediaList: BudgetOrdererDataProps[]) => {
    return new Promise((resolve, reject) => {
      campaignService.insertMediaListNodes(campaignSectionId, mediaList).then(
        response => {
          if (response.codigo === 0) {
            const updatedItem: CampaignItem = response.respuesta
            const items = get().campaignItemss
            const mediaItem = get().selectedFlowMedia

            items.map(item => {
              if (updatedItem.id === item.id) {
                item = updatedItem
              }

              return item
            })

            set({ campaignItemss: items, selectedCampaign: updatedItem, selectedFlowMedia: updatedItem.mediaList?.find(e => e.id === mediaItem?.id) })
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch(() => reject())
    })
  },

  setSelectedCampaign: (item: CampaignItem) => set({ selectedCampaign: item }),

  setSelectedBudget: (item: Planning) => set({ selectedBudget: item }),

  setSelectedFlowMedia: (item: MediaList) => set({ selectedFlowMedia: item }),

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useCampaignStore = create<CampaignState>()(
  persist(
    immer(Store),
    {
      name: 'campaigns-store',
      storage: SecureSessionStorage
    }
  )
)