import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import DocumentCommentsView from './DocumentCommentsView'
import { useComponentsStore } from '@/core/stores/components.store'
import { Button, Flex, Text } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useModalStore } from '@/core/stores/modal.store'
import { useConciliationStore } from '../stores/conciliation.store'
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store'

interface DocumentViewProps {
  title?: string
  isCarousel?: boolean
}

function DocumentViewTemplate({ title, isCarousel = false }: DocumentViewProps) {
  const { uiConciliationComponents } = useComponentsStore()
  const { selectedFile, selectedBillingMonth } = useConciliationStore()
  const { selectedConciliation } = useConciliationStore()
  const openModal = useModalStore(e => e.openModal);
  return (
    <>
      {
        ((title === 'Factura') || (title === 'Orden') || (title === 'Carta')) ?
          <BrandPageHead subtitle={selectedFile?.name} type={title}>
            {(title === 'Factura' && uiConciliationComponents.approveBilling && selectedBillingMonth?.status === 'revision') ? <Button mb={2} mr={2} mt={2} rightIcon={<CheckIcon />} onClick={() => openModal('Billingapprove')}>Aprobar factura</Button> :
              <Flex gap={2} alignItems={'center'}>
                <Text fontWeight="bold">Campaña:</Text>
                <Text>{selectedConciliation?.name}</Text>
              </Flex>}
          </BrandPageHead>
          :
          <BrandPageHead subtitle={title}>
            <Flex gap={2} alignItems={'center'}>
              <Text fontWeight="bold">Campaña:</Text>
              <Text>{selectedConciliation?.name}</Text>
            </Flex>
          </BrandPageHead>
      }
      <DocumentCommentsView type={title} isCarousel={isCarousel} />
    </>
  )
}

export default DocumentViewTemplate