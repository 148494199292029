import BrandBox from '@/core/components/molecules/BrandBox';
import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useConciliationStore } from '../stores/conciliation.store';
import { versions } from '../models/conciliation.model';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useComponentsStore } from '@/core/stores/components.store';
import { useState } from 'react';
import useMountedEffect from '@/core/hooks/useMountedEffect';
import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage';
import { BiPlus, BiPlusCircle } from 'react-icons/bi';
import { useModalStore } from '@/core/stores/modal.store';
import UploadPurchaseOrderModal from './UploadPurchaseOrderModal';
import PurchaseOrderDocumentView from './PurchaseOrderDocumentView';

interface BuyOrderProps {
  tvType: string;
  provider: string;
  folio: string
  versions: versions[]
}

interface OrderProps {
  order: BuyOrderProps
}

function BuyOrdersPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { buyOrders, setSelectedBuyOrderProvider, selectedConciliation, setPurchaseOrder, selectedPurchaseOrder } = useConciliationStore();
  const openModal = useModalStore(e => e.openModal);
  const [selectedPdf, setSelectedPdf] = useState<File | null>(null)

  const handleClick = (order: BuyOrderProps) => {
    setSelectedBuyOrderProvider(order.provider)
    navigate('/views/reconciliations/buy-order');
  }

  useMountedEffect(() => {
    if (uiConciliationComponents.seeOrdersProviders) {
      console.log(selectedPdf)
    } else {
      if (selectedConciliation?.purchaseOrderList && selectedConciliation?.purchaseOrderList.length > 0) {
        const orderList = selectedConciliation?.purchaseOrderList
        const lastOrder = orderList[orderList.length - 1]
        setPurchaseOrder(lastOrder)
      }
    }
  })

  const RowHover = ({ order }: OrderProps) => {
    return <Tr className='group hover:bg-blue-100'>
      <Td>
        <Flex columnGap={6}>
          <Text fontWeight={'bold'}>
            {order.tvType}
          </Text>
          <Text>
            {order.provider}
          </Text>
        </Flex>
      </Td>
      <Td>Folio: <b>{order.folio}</b></Td>
      <Td isNumeric>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant='ghost'
          className='opacity-0 group-hover:opacity-100 transition-opacity'
          onClick={() => handleClick(order!)}
        >Ver órden de compra</Button>
      </Td>
    </Tr>
  }

  return (
    <>
      {/* {
        uiConciliationComponents.seeOrdersProviders ?
          selectedConciliation?.purchaseOrderList && selectedConciliation?.purchaseOrderList.length > 0 ?
            <BrandBox>
              <Text fontSize='md'>Listado de proveedores</Text>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th fontWeight='bold' color='black' fontSize='sm'>Órdenes de compra</Th>
                      <Th fontWeight='bold' color='black' fontSize='sm'>Folio</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {buyOrders.map((order, index) => (
                      <RowHover order={order} key={index} />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </BrandBox> :
            <EmptyDataMessage text='No hay proveedores asignados'>
              <Button rightIcon={<BiPlusCircle />} onClick={() => console.log('')} colorScheme="brand">
                Agregar proveedor
              </Button>
            </EmptyDataMessage>
          :
          selectedPurchaseOrder ?
            <>
              <PurchaseOrderDocumentView />
            </>
            :
            <EmptyDataMessage text='No existen órdenes de compra'>
              <Button rightIcon={<BiPlus />} onClick={() => openModal('UploadPurchaseOrderModal')} colorScheme="brand">
                Subir órden de compra
              </Button>
            </EmptyDataMessage>
      } */}
      {
        selectedPurchaseOrder ?
          <>
            <PurchaseOrderDocumentView />
          </>
          :
          <EmptyDataMessage text='No existen órdenes de compra'>
            <Button rightIcon={<BiPlus />} onClick={() => openModal('UploadPurchaseOrderModal')} colorScheme="brand">
              Subir órden de compra
            </Button>
          </EmptyDataMessage>
      }
      <UploadPurchaseOrderModal setSelectedFile={setSelectedPdf} />
    </>
  );
}

export default BuyOrdersPage;
