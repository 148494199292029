import BrandBox from '@/core/components/molecules/BrandBox'
import { useConciliationStore } from '@/modules/conciliation/stores/conciliation.store'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Badge, Box, Flex, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface BrandPageHeadProps {
  title?: string
  subtitle?: string
  buttonTitle?: string
  children?: React.ReactNode
  type?: string
  backButton?: boolean
}

function BrandPageHead({ title, subtitle, children, type, backButton = true }: BrandPageHeadProps) {
  const navigate = useNavigate();
  const { selectedWitnessesMonth, selectedBillingMonth } = useConciliationStore();

  const handleBack = () => {
    navigate(-1); // -1 indica que queremos ir una página atrás en el historial
  };

  return (
    <BrandBox className='mb-3'>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex gap={2} alignItems={'center'}>
          {
            backButton &&
            <IconButton
              aria-label="Back"
              icon={<ArrowBackIcon />}
              colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
              variant="ghost"
              onClick={handleBack} // Agrega la función de retroceso
            />
          }
          <Text>{title}</Text>
          <Text fontWeight="bold">
            {subtitle}
            {type === 'Factura' && <Badge rounded='full' ml={2} px={2} colorScheme={selectedBillingMonth?.status === 'revision' ? 'yellow' : 'green'}>{selectedBillingMonth?.status}</Badge>}
            {type === 'evidences' && <Badge rounded='full' ml={2} px={2} colorScheme={selectedWitnessesMonth?.status === 'Pendiente' ? 'yellow' : 'green'}>{selectedWitnessesMonth?.status}</Badge>}
          </Text>
        </Flex>
        <Box>
          {children}
        </Box>
      </Flex>
    </BrandBox>
  )
}

export default BrandPageHead