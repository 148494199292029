import { TranslationObject } from "./en";

const es: TranslationObject = {
  global: {
    //words
    login_welcome: 'Bienvenido a OrkestaMedia',
    login_password: 'Contraseña',
    login_btn1_text: 'Iniciar sesión',
    login_btn2_text: 'Olvidé mi contraseña',
    //areas
    welcome_hi: 'Hola',
    welcome_desc: 'Identificamos estos accesos para tu tipo de cuenta',
    area_title: 'Área',
    create_area: 'Crear área',
    required_file_area: 'El archivo para el área es requerido',
    input_area_placeholder: 'Ingresa el nombre del área',
    create_button_text: 'Crear',
    enter_button_text: 'Entrar',
    //file components
    file_drop_title: 'Puedes arrastrar y soltar un archivo aquí',
    file_drop_title_or: 'ó',
    file_drop_button_text: 'Seleccionar archivo dando click aquí',
    logout_text: 'Cerrar sesión',
    admin_text: 'Administración',
    indicator_text1: 'Total de campañas',
    indicator_text2: 'Campañas activas',
    indicator_text3: 'Total de presupuestos',
    indicator_text4: 'Presupuestos activos',
    dashboard_updates: 'Actualizaciones',
    dashboard_members: 'Miembros',
    dashboard_news_not: 'Nuevos',
    dashboard_total_budget: 'Presupuesto total',
    dashboard_consumption: 'Total consumido',
    dashboard_consumption_by_media: 'Consumos por medio',
    
  },
};

export default es;
