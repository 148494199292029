import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import useMountedEffect from '@/core/hooks/useMountedEffect'
import { UtilService } from '@/core/services/util.service'
import { DataUtil } from '@/core/utils/data.util'
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useConciliationStore } from '../stores/conciliation.store'
import useCustomToast from '@/core/hooks/useCustomToast'

const WinessesAddFolderModal = () => {
  const { selectedWitnessesProvider, updateFolders } = useConciliationStore()
  const toast = useCustomToast();

  const handleGenerateName = () => {
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' }); // Para obtener el mes completo en texto
    const year = currentDate.getFullYear();

    const monthYearString = `${month} ${year}`;
    loadFileForm.setFieldValue('name', DataUtil.capitalizeText(monthYearString))
  }

  const handleCreate = () => {
    console.log(selectedWitnessesProvider)
    updateFolders(selectedWitnessesProvider?.provider!, loadFileForm.values.name, loadFileForm.values.evidences)
    toast.success('Evidencia cargada con éxito')
  }

  const loadFileForm = useFormik({
    onSubmit: handleCreate,
    validateOnBlur: true,
    initialValues: {
      name: '',
      evidences: 0
    }
  })

  useMountedEffect(() => {
    handleGenerateName()
  })

  return (
    <CustomAlertDialog
      id="WinessesAddFolderModal"
      title={`Crear carpeta de evidencias`}
      cancelText="Cancelar"
      acceptText="Crear carpeta"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={loadFileForm.handleSubmit}
    >
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FormControl isInvalid={!!loadFileForm.errors.name} mb={1}>
            <FormLabel fontWeight={'normal'}>Nombre</FormLabel>
            <InputGroup>
              <Input id="name" type="text" placeholder="Nombre"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.name}</FormErrorMessage>
          </FormControl>
          {/* <FormControl isInvalid={!!loadFileForm.errors.evidences} mb={1}>
            <FormLabel fontWeight={'normal'}>Número de evidencias</FormLabel>
            <InputGroup>
              <Input id="evidences" type="number" placeholder="Número de evidencias"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.evidences}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.evidences}</FormErrorMessage>
          </FormControl> */}
        </form>
      )}
    </CustomAlertDialog>
  )
}

export default WinessesAddFolderModal