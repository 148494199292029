import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useExcelTableStore } from "@/core/stores/excel-table.store"
import { useCampaignStore } from "../store/campaign.store"

const MediasBuildModal: React.FC = () => {
  const toast = useCustomToast()
  const { selectedCampaign, insertMediaListNodes } = useCampaignStore()
  const { budgetData } = useExcelTableStore();


  const handleExcelFile = () => {
    insertMediaListNodes(selectedCampaign?.id!, budgetData).then(() => {
      toast.success('Se han construido los medios correctamente')
    }).catch(
      () => toast.error('Ocurrió un error al crear lista de medios')
    )
  }

  return (
    <CustomAlertDialog
      id="MediasBuildModal"
      title="Construir medios"
      cancelText="No"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleExcelFile}
    >
      {() => (
        <Box>
          <Text>Está por construir los medios basándose en la versión del presupuesto seleccionado.</Text>
          <Text fontWeight='semibold'>¿Desea continuar con esta operación?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default MediasBuildModal