import BrandBox from '@/core/components/molecules/BrandBox'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FiCalendar, FiImage, FiMapPin, FiVideo } from 'react-icons/fi'
import { useCampaignStore } from '../store/campaign.store';
import { DataUtil } from '@/core/utils/data.util';

function CoverPage() {
  const selectedCampaign = useCampaignStore(e => e.selectedCampaign);
  return (
    <Flex columnGap={5} alignItems='start'>
      <BrandBox className='w-[35%]'>
        <Text fontSize="lg" fontWeight="bold" mb={2} py={2}>
          Datos principales
        </Text>
        <Divider />
        <Flex flexDirection='column' mt={3} rowGap={3}>
          <Box display={'flex'} alignItems={'center'} columnGap={2}>
            <FiVideo />
            <Box display="flex" gap={2} width="100%">
              <Text fontWeight="bold">Anunciante:</Text>
              <Text>{selectedCampaign?.advertiser}</Text>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} columnGap={2}>
            <FiImage />
            <Box display="flex" gap={2} width="100%">
              <Text fontWeight="bold">Campaña:</Text>
              <Text>{selectedCampaign?.name}</Text>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} columnGap={2}>
            <FiCalendar />
            <Box display="flex" gap={2} width="100%">
              <Text fontWeight="bold">Periodo:</Text>
              <Text>{selectedCampaign?.beginDate} - {selectedCampaign?.expirationDate}</Text>
            </Box>
          </Box>
        </Flex>
      </BrandBox>
      <BrandBox className='w-[65%]'>
        <Text fontSize="lg" fontWeight="bold" mb={2} py={2}>
          Datos de medio
        </Text>
        <Divider />
        <Flex flexDirection='column' mt={3} rowGap={3}>
          {
            selectedCampaign?.mediaList?.map(e => (
              <Box key={e.id}>
                <Text fontWeight="bold">{e.name}</Text>
                <Text>Presupuesto asignado: {DataUtil.numberCurrencyFormat(e.amount)}</Text>
              </Box>
            ))
          }
        </Flex>
      </BrandBox>
    </Flex>
  )
}

export default CoverPage