import CustomModal from "@/core/components/organism/CustomModal"
import FileDropzone from "@/core/components/organism/FileDropzone"
import { useModalStore } from "@/core/stores/modal.store"
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, useBoolean } from "@chakra-ui/react"
import { useFormik } from "formik"
import { FcUpload } from "react-icons/fc"
import { useState } from "react"
import { FilesUtil } from "@/core/utils/files.util"
import { useConciliationStore } from "../stores/conciliation.store"
import { UtilService } from "@/core/services/util.service"
import useCustomToast from "@/core/hooks/useCustomToast"
import { DatesUtil } from "@/core/utils/dates.util"

interface LoadProps {
  setSelectedFile?: (e: File | null) => void
  accept?: string
}

const ConciliationLoadFileModal: React.FC<LoadProps> = ({ setSelectedFile, accept }) => {
  const utilService = new UtilService()
  const toast = useCustomToast()

  const closeModal = useModalStore(s => s.closeModal)
  const [hasErrorFile, setHasErrorFile] = useBoolean(false)
  const { insertConciliationWitness, selectedConciliation, selectedWitnessesPeriod } = useConciliationStore();
  const [file, setFile] = useState<File | null>(null)

  const handleBudgetFile = () => {
    if (file === null) {
      setHasErrorFile.on()
      return
    }
    setHasErrorFile.off()

    const extension = FilesUtil.extractFileExtension(file)
    const formData = new FormData()

    formData.append("file", file as File)

    utilService.uploadFile(formData!).then(
      response => {
        if (response.codigo === 0) {
          insertConciliationWitness(selectedConciliation?.id!, {
            fileId: response.respuesta,
            fileExt: extension,
            mediaName: selectedWitnessesPeriod?.mediaName,
            name: loadFileForm.values.name,
            targetDate: DatesUtil.formatDateToString(new Date(selectedWitnessesPeriod?.periodName!)),
            providerName: selectedWitnessesPeriod?.providerName
          })
          toast.success('Evidencia guardada con éxito')
          closeModal('ConciliationLoadFileModal')
        }
      }
    ).catch(
      () => toast.error('Ocurrió un error al subir el archivo')
    )
  }

  const loadFileForm = useFormik({
    onSubmit: handleBudgetFile,
    validateOnBlur: true,
    initialValues: {
      name: '',
    }
  })

  const handleFileUpload = (file: File) => {
    setFile(file);
  }

  return (
    <CustomModal
      id="ConciliationLoadFileModal"
      title="Cargar nueva evidencia"
      size="xl"
      icon={FcUpload}
      showCancelButton={false}
      onAccept={loadFileForm.handleSubmit}
      closeOnAccept={false}
      acceptText="Cargar"
    >
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FormControl isInvalid={!!loadFileForm.errors.name} mb={1}>
            <FormLabel fontWeight={'normal'}>Descripción</FormLabel>
            <InputGroup>
              <Input id="name" type="text" placeholder="Descripción"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.name}</FormErrorMessage>
          </FormControl>
          <FileDropzone accept={accept === 'pdf' ? 'application/pdf' : accept === 'image' ? 'image/*' : '*'} onDropFile={handleFileUpload} />
          {hasErrorFile && (
            <span className="text-red-500">
              El archivo es requerido
            </span>
          )}
        </form>
      )}
    </CustomModal>
  )
}

export default ConciliationLoadFileModal