export const getColorBadge = (status?: string) => {
  switch(status) {
    case 'PROCESO': return 'yellow'
    case 'ACTIVA': return 'green'
    case 'CERRADA' : return 'red'
  }
}

export const getColorBadgeV2 = (status?: string) => {
  switch(status) {
    case 'revision': return 'yellow'
    case 'aprobado': return 'green'
    case 'rechazado' : return 'red'
  }
}