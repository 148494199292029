import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { useConciliationStore } from '../stores/conciliation.store'
import { ConciliationBilling, FileReviewable } from '../models/conciliation.model'
import { CurrentStatus } from '@/modules/campaigns/enums/campaign.enum'
import useCustomToast from '@/core/hooks/useCustomToast'

interface WitnessProps {
  witness: FileReviewable
}

const EvidenceApproveModal = ({ witness }: WitnessProps) => {
  const toast = useCustomToast()
  const { updateConciliationWitnessStatus, selectedConciliation } = useConciliationStore();
  const handleApproveEvidence = async () => {
    await updateConciliationWitnessStatus(selectedConciliation?.id!, witness.fileId, CurrentStatus.aprobado).then(e => {
      toast.success('Evidencia aprobada con éxito')
    }).catch((error) => {
      toast.success('Error al aprobar evidencia')
      console.error(error)
    })
  }

  return (
    <CustomAlertDialog
      id="EvidenceApproveModal"
      title={`Aprobar evidencia`}
      cancelText="Cancelar"
      acceptText="Si, Aprobar"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleApproveEvidence}
    >
      {() => (
        <Box>
          <Text mb={1}>La evidencia está por aprobarse</Text>
          <Text mb={1}>¿Está seguro de continuar con el proceso?</Text>
          {/*  {
            approveRequirements.map((e, index) => (
              <Flex key={index} color='green' alignItems='center' columnGap={1} mb={1}>
                <IoMdCheckmarkCircleOutline /><Text>{e}</Text>
              </Flex>
            ))
          } */}
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default EvidenceApproveModal