import { Box, Button, Flex, IconButton, Input, InputGroup, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FiSend } from 'react-icons/fi'
import CommentBody from './CommentBody'
import { DatesUtil } from '@/core/utils/dates.util'
import { Comment } from '../models/campaign.model'
import { useUserStore } from '@/modules/auth/stores/user.store'

interface CommentProps {
  comments?: Comment[]
  sendComments?: (comments: Comment[]) => void
}

const CommentsPanel = ({ comments, sendComments }: CommentProps) => {
  const [comment, setComment] = useState<string>();
  const userInfo = useUserStore();

  const addComment = () => {
    const body: Comment[] = [
      ...(comments || []), // Si `comments` es undefined, usa un array vacío
      {
        comment: comment ?? '',
        userId: userInfo.userData?.id,
        userName: `${userInfo.userData?.firstName} ${userInfo.userData?.lastName}`,
      }
    ];
    if (sendComments) sendComments(body)
    setComment('');
  }
  return (
    <Box w={'30%'}>
      <Box bgColor='gray.100' p={3} borderTopRadius='5px'>
        <Text fontSize="md" fontWeight="bold" mr="2">
          Comentarios
        </Text>
      </Box>
      <Box bgColor='white' height='50vh' p={3} overflowY={'auto'}>
        <Flex flexDirection='column' gap={2}>
          <>
            {comments?.map((item, index) => <CommentBody key={index} item={item} />)}
          </>
        </Flex>
      </Box>
      <Box bgColor='gray.100' p={3} borderBottomRadius='5px'>
        <InputGroup gap={1}>
          <Input placeholder="Comentario"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            colorScheme="brand"
            as={IconButton}
            icon={<FiSend size={20} />}
            onClick={addComment}
          >Enviar</Button>
        </InputGroup>
      </Box>
    </Box>
  )
}

export default CommentsPanel