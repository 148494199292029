import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import useCustomToast from "@/core/hooks/useCustomToast"
import { usePlanningStore } from "../store/planning.store"
import { useExcelTableStore } from "@/core/stores/excel-table.store"
import { PlanningStatus } from "@/core/enums/planning.enum"

const MediasBuildModal: React.FC = () => {
  const toast = useCustomToast()
  const { selectedPlanning, updateStatusPlanning, insertMediaListNodes } = usePlanningStore()
  const { budgetData } = useExcelTableStore();


  const handleExcelFile = () => {
    console.log(budgetData)
    insertMediaListNodes(selectedPlanning?.id!, budgetData).then(() => {
      updateStatusPlanning(selectedPlanning?.id!, PlanningStatus.Actived).then(() => {
        toast.success('Se han construido los medios correctamente')
      }).catch(() => toast.error('Ocurrió un error al cambiar estatus'))
    }).catch(
      () => toast.error('Ocurrió un error al crear lista de medios')
    )
  }

  /* useMountedEffect(() => {
    openBudgetFile()
  }) */

  /* const handleSaveExcelFile = () => {

  } */

  return (
    <CustomAlertDialog
      id="MediasBuildModal"
      title="Construir medios"
      cancelText="No"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleExcelFile}
    >
      {() => (
        <Box>
          <Text>Está por construir los medios basándose en la versión del presupuesto seleccionado.</Text>
          <Text fontWeight='semibold'>¿Desea continuar con esta operación?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default MediasBuildModal