import BrandBox from '@/core/components/molecules/BrandBox'
import { AspectRatio, Badge, Box, Divider, Flex, Text } from '@chakra-ui/react'
import { FileReviewable, PeriodList } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'
import { useEffect, useState } from 'react'
import { DatesUtil } from '@/core/utils/dates.util'

export interface Evidences {
  fileId?: string
  name?: string
}

export interface Witnesses {
  provider: string
  mediaType: string
  month: string
  evidences: Evidences[]
}

interface WitnessesProps {
  period?: PeriodList
}

const BillingCard = ({ period }: WitnessesProps) => {
  const selectedConciliation = useConciliationStore(e => e.selectedConciliation)
  const [evidences, setBills] = useState<FileReviewable[]>([])

  useEffect(() => {
    const loadImages = async () => {
      const ev = selectedConciliation?.billingList?.filter(e =>
        (e.mediaName === period?.mediaName) &&
        (e.providerName === period?.providerName) &&
        DatesUtil.handlePeriodMonth(e?.targetDate!, period?.month!, period?.year!)
      );
      setBills(ev!);
    };

    loadImages();
  }, [selectedConciliation, period]);



  return (
    <Box cursor='pointer' className='h-full'>
      <BrandBox className='!p-3 h-full'>
        <Flex gap={1} mb={2}>
          {
            <AspectRatio maxW='400px' w='100%' ratio={8 / 2}>
              <Text className='bg-gray-200 font-semibold'>
                {
                  evidences.length === 0 ?
                    <Badge rounded='full' ml={2} px={2} colorScheme={'yellow'}>Sin facturas cargadas</Badge> :
                    <Badge rounded='full' ml={2} px={2} colorScheme={'green'}>{evidences.length && evidences.length > 1 ? evidences.length + ' Facturas cargadas' : evidences.length + ' Factura cargada'}</Badge>
                }
              </Text>
            </AspectRatio>
          }
        </Flex>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text color='brand.500' fontWeight='semibold'>{period?.periodName}</Text>
          <Text color='gray' fontWeight='semibold'>{period?.mediaName}</Text>
        </Flex>
        <Divider pt={2} />
        <Box pt={2} fontWeight='semibold' justifyContent='space-between'>
          <Text>
            {
              period?.providerName
            }
          </Text>
        </Box>
      </BrandBox>
    </Box>
  )
}

export default BillingCard