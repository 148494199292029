import { useRef, useEffect } from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import SagaChatContainer from './SagaChatContainer';
import { useChatState } from '@/core/stores/saga-chat.store';

const SagaChat = () => {
    const chatState = useChatState((s) => s);
    const chatRef = useRef<HTMLDivElement | null>(null);

    // Hook para manejar clics fuera del chat
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (chatRef.current && !chatRef.current.contains(event.target as Node)) {
                chatState.changeChatState(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [chatState]);

    return (
        <Box position="relative">
            {chatState.chatOpen && (
                <Box
                    ref={chatRef}
                    position="fixed"
                    right={'1%'}
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    width="500px"
                    height="80%"
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                    bottom='1%'
                >
                    <Box
                        bg="blue.600"
                        color="white"
                        padding="4"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Heading as="h4" size="md">SAGA</Heading>
                        <Text cursor="pointer" onClick={() => chatState.changeChatState(false)}>Cerrar</Text>
                    </Box>
                    <Box padding="4" overflowY="auto" flex="1">
                        <SagaChatContainer />
                    </Box>
                </Box>
            )}
            {chatState.chatOpenFromPDF && (
                <Box
                    position="absolute"
                    right="0"
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    width="300px"
                    maxHeight="500px"
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                >
                    <Box
                        bg="blue.600"
                        color="white"
                        padding="4"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Heading as="h4" size="md">SAGA</Heading>
                        <Button
                            variant="ghost"
                            colorScheme="whiteAlpha"
                            onClick={() => chatState.changeChatOpenFromPDF(false)}
                        >
                            Cerrar
                        </Button>
                    </Box>
                    <Box padding="4" overflowY="auto" flex="1">
                        <SagaChatContainer />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SagaChat;
