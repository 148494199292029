import { useComponentsStore } from '@/core/stores/components.store'
import { Box, Button, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useConciliationStore } from '../stores/conciliation.store'
import BillingFilters from './BillingFilters'
import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage'
import BillingCard from './BillingCard'
import { PeriodList } from '../models/conciliation.model'
import { DatesUtil } from '@/core/utils/dates.util'


function BillingPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { setWitnessEvidences, setSelectedBillingProvider, setSelectedWitnessesPeriod, selectedConciliation, filteredWitness } = useConciliationStore();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Número de elementos por página

  // Calcular los elementos de la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWitness?.slice(indexOfFirstItem, indexOfLastItem);

  // Cambiar de página
  const totalPages = Math.ceil(filteredWitness?.length! / itemsPerPage);
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  /* const handleClick = (provider: string) => {
    setSelectedBillingProvider(provider)
    navigate('/views/reconciliations/billing-list');
  } */

  const handleClick = (provider: PeriodList) => {
    const sl = selectedConciliation?.billingList?.filter((e) => e.mediaName === provider?.mediaName && e.providerName === provider?.providerName && DatesUtil.handlePeriodMonth(e?.targetDate!, provider?.month!, provider?.year!))
    setWitnessEvidences(sl!)
    setSelectedWitnessesPeriod(provider)
    navigate('/views/reconciliations/billing-list')
  }

  useEffect(() => {
    uiConciliationComponents.seeBillingProviders ? setSelectedBillingProvider('') : setSelectedBillingProvider('TV Azteca')
    //setSelectedWitnessesProvider('')
  }, [])

  return (
    /* uiConciliationComponents.seeBillingProviders ?
      <BrandBox>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th fontWeight='bold' color='black' fontSize='sm'>Proveedores</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                conciliationBilling.map((conciliation: ConciliationBilling, index: number) => {
                  return <RowHover key={index} conciliation={conciliation} />
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </BrandBox> :
      <BillingListPage /> */
    <>
      <BillingFilters />
      {
        filteredWitness?.length! > 0 ?
          <>
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
              {
                currentItems?.map((period) => (
                  <Box key={`${period.periodName}-${period.providerName}-${period.mediaName}`} onClick={() => handleClick(period)}>
                    <BillingCard period={period} />
                  </Box>
                ))
              }
            </SimpleGrid>
            {/* Controles de paginación */}
            <HStack spacing={4} mt={6} justify="end">
              <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>
                Anterior
              </Button>
              <Text>
                Página {currentPage} de {totalPages}
              </Text>
              <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </HStack>
          </>
          :
          <EmptyDataMessage text='No se encontraron coincidencias' />
      }
    </>

  )
}

export default BillingPage