import { Apis } from "@/apis"
import { PlanningStatus } from "@/core/enums/planning.enum"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { BudgetCard, Planning, RoadMap, SubSectionList } from "@/core/models/planning.model"
import { CampaignItem, subSectionList, VersionList } from "../models/campaign.model"
import { CampaignStatus, CurrentStatus } from "../enums/campaign.enum"
import { BudgetOrdererDataProps } from "@/modules/planning/store/planning.store"

export class CampaignService {
  /** */
  private http = new HttpClient(`${Apis.ORKESTAMEDIA}/api/v1/campaignSection`)
  private base = new HttpClient(`${Apis.ORKESTAMEDIA}/api/v1/`)
  private http2 = new HttpClient(`${Apis.ORKESTAMEDIA}/api/v1/planningSection`)

  public getCampaigByPlanning(planningSectionId: string): Promise<GenericApiResponse> {
    return this.http.get(`/planningSectionId/${planningSectionId}`)
  }

  public getCampaignBySection(sectionId: string): Promise<GenericApiResponse> {
    return this.http.get(`/sectionId/${sectionId}`)
  }

  public getCampaignObjetives(companyId: string): Promise<GenericApiResponse> {
    return this.base.get(`/objetive/companyId/${companyId}`)
  }

  public getCampaignPlaces(companyId: string): Promise<GenericApiResponse> {
    return this.base.get(`/place/companyId/${companyId}`)
  }

  public createCampaign(body: CampaignItem): Promise<GenericApiResponse> {
    return this.http.put('', body)
  }

  public createCampaignBriefNewComment(campaignSectionId: string, comment: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/briefFile/comment/${comment}`, {})
  }

  public createCampaignBriefFile(campaignSectionId: string, fileId: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/briefFile/fileId/${fileId}`, {})
  }

  public createCampaignStrategyNewComment(campaignSectionId: string, comment: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/strategyFile/comment/${comment}`, {})
  }

  public createCampaignStrategyFile(campaignSectionId: string, fileId: string): Promise<GenericApiResponse> {
    return this.http.put(`${campaignSectionId}/strategyFile/fileId/${fileId}`, {})
  }
  
  public createCampaignFlowFile(campaignSectionId: string, mediaId: string, fileData: VersionList): Promise<GenericApiResponse> {
    return this.http.put(`fileVersion/${campaignSectionId}/media/${mediaId}`, fileData)
  }

  public insertMediaListNodes(campaignSectionId: string, body: BudgetOrdererDataProps[]): Promise<GenericApiResponse> {
    return this.http.put(`/${campaignSectionId}/mediaList`, body)
  }


  public updateCampaign(body: CampaignItem): Promise<GenericApiResponse> {
    return this.http.patch('', body)
  }

  public updateCampaignCurrentStatusBrief(campaignSectionId: string, currentStatus: CurrentStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/briefFile/currentStatus/${currentStatus}`, {})
  }

  public updateCampaignImage(campaignSectionId: string, extension: string, imageId: string): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/imageId/${imageId}/extension/${extension}`, {})
  }

  public updateCampaignStatus(campaignSectionId: string, status: CampaignStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/status/${status}`, {})
  }

  public updateCampaignCurrentStatusStrategy(campaignSectionId: string, currentStatus: CurrentStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/strategyFile/currentStatus/${currentStatus}`, {})
  }

  public updateCampaignSubSection(campaignSectionId: string, subSection: subSectionList): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/subSection`, subSection)
  }

  public updateCampaignSubSectionList(campaignSectionId: string, subSection: subSectionList): Promise<GenericApiResponse> {
    return this.http.patch(`/${campaignSectionId}/subSectionList`, subSection)
  }






  //////////////////////////

  public getPlanningBySection(sectionId: string): Promise<GenericApiResponse> {
    return this.http2.get(`/sectionId/${sectionId}`)
  }

  public updatePlanning(body: Planning): Promise<GenericApiResponse> {
    return this.http2.patch('', body)
  }

  public createPlanning(body: Planning): Promise<GenericApiResponse> {
    return this.http2.put('', body)
  }

  public updateBudgetCardPlanning(planningSectionId: string, body: BudgetCard[]): Promise<GenericApiResponse> {
    return this.http2.patch(`/${planningSectionId}/budgetCardList`, body)
  }

  public updateRoadMapPlanning(planningSectionId: string, items: RoadMap[]): Promise<GenericApiResponse> {
    return this.http2.patch(`/${planningSectionId}/roudMapList`, items)
  }

  public updateStatusPlanning(planningSectionId: string, status: PlanningStatus): Promise<GenericApiResponse> {
    return this.http2.patch(`/${planningSectionId}/status/${status}`, {})
  }

  public budgetCardVault(planningSectionId: string, temporalIdVault: string): Promise<GenericApiResponse> {
    return this.http2.put(`/${planningSectionId}/budgetCard/${temporalIdVault}`, {})
  }

  public updatePlanningSubSection(planningSectionId: string, items: SubSectionList): Promise<GenericApiResponse> {
    return this.http2.patch(`/${planningSectionId}/subSection`, items)
  }

  public updatePlanningSubSectionList(planningSectionId: string, items: SubSectionList[]): Promise<GenericApiResponse> {
    return this.http2.patch(`/${planningSectionId}/subSectionList`, items)
  }

}