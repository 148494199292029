import React, { useState, useRef, useEffect, ChangeEvent, MouseEvent, KeyboardEvent } from 'react';
import { Box, Button, CircularProgress, Textarea, Text } from "@chakra-ui/react";
import { FiSend } from 'react-icons/fi';

const MAX_CHARS = 4000;

interface TypingBarProps {
    isAnswering: boolean;
    sendMessage: (message: string) => void;
}

export const TypingBar: React.FC<TypingBarProps> = ({ isAnswering, sendMessage }) => {
    const [message, setMessage] = useState<string>('');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        adjustTextareaHeight();
    }, [message]);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        if (text.length <= MAX_CHARS) {
            setMessage(text);
        }
    };

    const handleSend = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        if (message.trim().length > 0) {
            sendMessage(message.trim());
            setMessage('');
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend(e);
        }
    };

    return (
        <>
            {isAnswering ? (
                <CircularProgress isIndeterminate color="blue.300" />
            ) : (
                <Box display="flex" flexDirection="column" w="100%" borderRadius="8px" bg="gray.50" p={4}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Text fontSize="sm" color="gray.500">
                            {message.trim().length}/{MAX_CHARS}
                        </Text>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Textarea
                            
                            placeholder={isAnswering ? "Respondiendo..." : "Escribe aquí..."}
                            value={message}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            resize="none"
                            bg="white"
                            disabled={isAnswering}
                            rows={1}
                            maxLength={MAX_CHARS}
                            flex="1"
                            mr={4}
                        />
                        <Button
                            colorScheme="blue"
                            onClick={handleSend}
                            isDisabled={message.trim().length === 0 || isAnswering}
                            leftIcon={<FiSend />}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};
