import { useEffect, useRef, useState } from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { HistoryChats } from "./HistoryChats";
import { TypingBar } from "./TypingBar";
import { ChatList } from "./ChatList";
import { MdChat } from "react-icons/md"; // Nuevo icono de React Icons
import { useChatState } from "@/core/stores/saga-chat.store";
import { EventSourcePolyfill } from "event-source-polyfill";
import { replaceMenu } from "@/redux/drawerSlice";
import chatServiceIa from "@/core/services/saga/chat_service_ai";

interface Message {
  ask: string;
  answer: string;
  metadata?: string[];
  subdata?: string;
  img?: string;
}

export interface ChatHistory {
  id: number;
  primary: string;
  secondary: string;
  metadata: string[];
  image: string | null;
  subdata: string | null;
  isSender: boolean;
}

interface ChatData {
  folderId: string | null;
  hash: string | null;
  history: Message[];
  question?: string | null;
  id?: number;
}

interface ChatContainerProps {
  documentHash?: string | null;
}

const messageTemplate: ChatHistory = {
  id: 1,
  primary: "",
  secondary: "",
  metadata: [],
  image: null,
  subdata: null,
  isSender: true,
};

const SagaChatContainer = ({ documentHash = null }: ChatContainerProps) => {
  const dispatch = useDispatch();
  const listRef = useRef<HTMLDivElement | null>(null);
  const eventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const chatState = useChatState((s) => s);
  const [chatsHistory, setChatsHistory] = useState<any>([]);
  const [messages, setMessages] = useState<ChatData>({ folderId: null, hash: documentHash, history: [] });
  const [formattedMessages, setFormattedMessages] = useState<{ history: ChatHistory[] }>({ history: [] });
  const [answering, setAnswering] = useState(false);
  const [historial, setHistorial] = useState(false);

  useEffect(() => {
    dispatch(replaceMenu({ component: 'HomeTemplate', props: { selected: 'chat' } }));
    loadChats();
    if (chatState.chatId === null) {
      newChat();
    } else {
      loadMessages(chatState.chatId);
    }
  }, [dispatch, documentHash]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [formattedMessages]);

  const loadChats = async () => {
    const folderId = '66e4889c95aa9735499db2c4';
    if (folderId) {
      const res = await chatServiceIa.getChats(folderId);
      setChatsHistory(res.reverse());
    }

    if (documentHash != null) {
      const res = await chatServiceIa.getChatsByDocumentHash(documentHash);
      setChatsHistory(res.reverse());
    }
  };

  const adapterMessages = (messageHistory: Message[] = []) => {
    const data = messageHistory.filter((n) => n.ask).flatMap((message) => {
      const result: ChatHistory[] = [];
      if (message.ask) {
        result.push({
          ...messageTemplate,
          primary: 'YOU',
          secondary: message.ask,
          isSender: true,
        });
      }
      if (message.answer) {
        result.push({
          ...messageTemplate,
          primary: 'BOT',
          secondary: message.answer,
          metadata: message.metadata || [],
          subdata: message.subdata || null,
          image: message.img || null,
          isSender: false,
        });
      }
      return result;
    });

    setFormattedMessages({ history: data });
  };

  const loadMessages = async (chatId: string) => {
    chatState.setChatId(chatId);
    const messages = await chatServiceIa.loadChat(chatId);
    setMessages(messages);
    adapterMessages(messages.history);
  };

  const sendMessage = (message: string) => {
    setAnswering(true);

    setMessages((prevMessages) => {
      const updatedHistory = [...prevMessages.history, { ask: message, answer: "-1" }];
      adapterMessages(updatedHistory);
      return { ...prevMessages, history: updatedHistory };
    });

    let msj = { ...messages, history: [], question: null };

    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const token = sessionStorage.getItem("access_token");
    const chatString = encodeURIComponent(JSON.stringify({ ...msj, ask: message }));

    eventSourceRef.current = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_AI}/api/v1/ai/chat/stream?chat=${chatString}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    eventSourceRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      chatState.setChatId(data.id);
      setMessages((prevMessages) => {
        const updatedHistory = [...prevMessages.history];
        const lastMessage = updatedHistory[updatedHistory.length - 1];
        lastMessage.answer = data.question.answer === "" ? "-1" : data.question.answer;
        lastMessage.metadata = data.question.metadata;
        lastMessage.img = data.question.img;
        lastMessage.subdata = data.question.subdata;
        adapterMessages(updatedHistory);
        return { ...prevMessages, id: data.id, history: updatedHistory };
      });
    };

    eventSourceRef.current.onerror = (error) => {
      setAnswering(false);
      if (msj.id === undefined) {
        loadChats();
      }
      console.error("Error en la conexión EventSource:", error);
      eventSourceRef.current?.close();
    };
  };

  const newChat = () => {
    const folderId = '';
    const data = { folderId, hash: documentHash, history: [] };
    setMessages(data);
    adapterMessages(data.history);
  };

  const handleSeletedDocument = async (data: any, metadata: string[]) => {
    return null;
  };

  const onClose = () => setHistorial(false);

  return (
    <Flex height="100%" width="100%" overflow="hidden">
      <Flex flex="1" justifyContent="center">
        <Flex flexDirection="column" width="100%" p={4}>
          <Box ref={listRef} flex="1" overflowY="auto" py={4}>
            <HistoryChats
              messages={formattedMessages.history}
              selectedDocument={handleSeletedDocument}
            />
          </Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p={4}
            bg="#F1F7FF"
            borderRadius="md"
          >
            <TypingBar isAnswering={answering} sendMessage={sendMessage} />
            {/* <IconButton
              aria-label="Open Chat History"
              icon={<MdChat />} // Ícono de React Icons
              onClick={() => setHistorial(!historial)}
              variant="ghost"
            /> */}
          </Flex>
        </Flex>
      </Flex>
      {historial && (
        <ChatList historyChats={chatsHistory} loadMessages={loadMessages} newChat={newChat} isOpen={historial} onClose={onClose} />
      )}
    </Flex>
  );
};

export default SagaChatContainer;
