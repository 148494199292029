class RestApi {
    constructor() {
        this.baseUrl = '/v1/';
        this.container = document.getElementById('root');
        let child = document.createElement('div');
        child.id = 'backdrop';
        this.div = this.container.appendChild(child);
        document.body.appendChild(this.div);
    }


    loadHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                "Accept": "*/*",
                'Content-Type': 'application/json',
            }
            return this.headers;
        }
    }

    loadMultipartHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Authorization': `Bearer ${token}`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                'Content-Type': 'application/json'
            }
            return this.headers;
        }
    }

    loadToken = () => {
        return new Promise((resolve, reject) => {
            let intentos = 0;
            const buscarValor = () => {
                const token = sessionStorage.getItem("access_token");
                if (token) {
                    resolve(token);
                } else {
                    if (intentos < 1) {
                        intentos++;
                        setTimeout(buscarValor, 1000); // Espera 1 segundo antes de intentar nuevamente
                    } else {
                        reject('No se encontró ningún valor en el localStorage después de 5 intentos');
                    }
                }
            };
            buscarValor();
        });
    };

    handleResponse = async (response) => {
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
        return response.json();
    };

    async get(url) {
        const headers = await this.loadHeaders();
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, { method: 'GET', headers: { ...headers } });
        return this.handleResponse(response);
    }

    async post(url, data) {
        const headers = await this.loadHeaders();
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
            redirect: 'follow'
        });
        return this.handleResponse(response);
    }

    async multipartPost(url, data) {
        const headers = await this.loadMultipartHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: data,
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async put(url, data) {
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async delete(url) {
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'DELETE',
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async fetchWithTimeout(endpoint, options = {}, timeout = 25000) {
        const baseUrl = `${process.env.REACT_APP_API_AI_GATEWAY}`;
        const url = `${baseUrl}${endpoint}`;
        
        const controller = new AbortController();
        const { signal } = controller;
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        try {
            const response = await fetch(url, { ...options, signal });
            clearTimeout(timeoutId);
            return response;
        } catch (error) {
            if (error.name === 'AbortError') {
                throw new Error('Fetch timeout');
            }
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    }
    
}

export default RestApi;