import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import FileCard from '@/core/components/molecules/FileCard';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import { BiPlus, BiUpload } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useConciliationStore } from '../stores/conciliation.store';
import WitnessesApproveModal from './WitnessesApproveModal';
import { useModalStore } from '@/core/stores/modal.store';
import ConciliationLoadFileModal from './ConciliationLoadFileModal';
import { useEffect, useState } from 'react';
import { EvidencesFiles, FileReviewable } from '../models/conciliation.model';
import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage';
import { DatesUtil } from '@/core/utils/dates.util';
import WitnessesFileCard from './WitnessesCardFile';

function WitnessesEvidencesPage() {
  const openModal = useModalStore(e => e.openModal)
  const { selectedWitnessesPeriod, witnessEvidences } = useConciliationStore()
  const [selectFile, setSelectFile] = useState<File | null>(null);

  return (
    <>
      <BrandPageHead title='Evidencias: ' subtitle={`${selectedWitnessesPeriod?.periodName} - ${selectedWitnessesPeriod?.providerName} - ${selectedWitnessesPeriod?.mediaName}`} type='evidences' >
        {
          <Button rightIcon={<BiUpload />} onClick={() => openModal('ConciliationLoadFileModal')}>
            Cargar evidencia
          </Button>
        }
      </BrandPageHead>
      {
        (witnessEvidences && witnessEvidences.length > 0) ? (
          <SimpleGrid columns={{ base: 1, lg: 4 }} spacing={4}>
            {
              witnessEvidences?.map((evidence, index) => (
                <WitnessesFileCard key={index} evidenceData={evidence} type='witness' />
              ))
            }
          </SimpleGrid>
        ) :
          <EmptyDataMessage text={``}>
            <Button
              rightIcon={<BiPlus />}
              colorScheme="brand"
              onClick={() => openModal('ConciliationLoadFileModal')}
            >
              Cargar evidencia
            </Button>
          </EmptyDataMessage>
      }
      <WitnessesApproveModal />
      <ConciliationLoadFileModal setSelectedFile={setSelectFile} accept='image' />
    </>
  )
}

export default WitnessesEvidencesPage