import ExcelTable from "@/core/components/organism/ExcelTable"
import FlowFiltersSections from "./FlowFiltersSections"
import { useCampaignStore } from "../store/campaign.store";
import { Box } from "@chakra-ui/react";

function MediaPage() {
  const { selectedFlowMedia } = useCampaignStore()

  return (
    <Box key={selectedFlowMedia?.name}>
      <FlowFiltersSections />
      <ExcelTable />
    </Box>
  )
}

export default MediaPage