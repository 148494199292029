import React, { useState } from 'react'
import { AspectRatio, Badge, Box, Button, Flex, IconButton, Image, Text, Tooltip } from '@chakra-ui/react'
import { RxDotsVertical } from 'react-icons/rx';
import { FilesUtil } from '@/core/utils/files.util';
import useMountedEffect from '@/core/hooks/useMountedEffect';
import { VaultService } from '@/core/services/vault.service';
import BrandBox from '@/core/components/molecules/BrandBox';
import { useNavigate } from 'react-router-dom';
import { Comment } from '@/modules/campaigns/models/campaign.model';
import { useConciliationStore } from '../stores/conciliation.store';
import { FileReviewable } from '../models/conciliation.model';
import { CurrentStatus } from '@/modules/campaigns/enums/campaign.enum';

interface WitnessesFileCardProps {
  evidenceData: FileReviewable
  type?: string
}

const WitnessesFileCard = ({ evidenceData, type }: WitnessesFileCardProps) => {
  const vaultService = new VaultService()
  const [imgSrc, setImgSrc] = useState<string>('')
  const [ext, setExt] = useState<string>('')
  const { setWitnessDocumentData } = useConciliationStore()

  const navigate = useNavigate()

  const handleClick = () => {
    setWitnessDocumentData({
      comments: evidenceData.comments ?? [],
      ext: evidenceData.fileExt!,
      file: evidenceData.fileId!,
      name: evidenceData.name!,
      status: evidenceData.currentStatus!
    })
    navigate(type === 'witness' ? '/views/reconciliations/witnesses/evidences/document-view' : '/views/reconciliations/billing-list/billing-view')
  }

  useMountedEffect(() => {
    if (evidenceData.fileId) {
      vaultService.getFile(evidenceData.fileId!).then(
        response => {
          //@ts-ignore
          const namesplit = response.name.split('.')
          //@ts-ignore
          setImgSrc(response.file)
          setExt(namesplit[namesplit.length - 1])
        }
      ).catch((error) => console.error(error))
    }
  })

  return (
    <Box onClick={handleClick} cursor='pointer'>
      <BrandBox>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center' gap={2}>
            <Image src={FilesUtil.handleIcon(ext)} /> <Text color='brand.500' fontWeight='semibold' fontSize='lg'>{evidenceData.name}</Text>
          </Flex>
          <Box>
            <Tooltip label='Opciones'>
              <Button
                as={IconButton}
                icon={<RxDotsVertical color='gray' size={20} />}
                variant="ghost"
                size="md"
                margin={'0'}
                padding={'6px'}
                borderRadius='full'
                onClick={(e) => console.log('open')}
              />
            </Tooltip>
          </Box>
        </Flex>
        <Box paddingY={2}>
          {evidenceData.fileExt !== 'xml' && evidenceData.fileExt !== 'pdf' && <AspectRatio ratio={4 / 3}>
            <Image src={`data:image/${ext};base64,${imgSrc}`} objectFit='cover' />
          </AspectRatio>
          }

        </Box>
        <Flex alignItems='center' justifyContent='space-between'>
          {evidenceData.comments !== undefined &&
            <Text fontWeight={'semibold'}>
              Comentarios ({evidenceData.comments.length})
            </Text>
          }
          {evidenceData.currentStatus &&
            <Badge colorScheme={evidenceData?.currentStatus! === CurrentStatus.revision ? 'yellow' : 'green'} rounded='full' paddingX={3}>
              {evidenceData.currentStatus}
            </Badge>
          }
        </Flex>
      </BrandBox>
    </Box>
  )
}

export default WitnessesFileCard