import BrandBox from '@/core/components/molecules/BrandBox'
import { AspectRatio, Badge, Box, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { EvidencesByMonth, EvidencesFiles, FileReviewable, PeriodList } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'
import useMountedEffect from '@/core/hooks/useMountedEffect'
import { useEffect, useState } from 'react'
import { DatesUtil } from '@/core/utils/dates.util'
import { VaultService } from '@/core/services/vault.service'

interface ConciliationLetter {
  provider?: string
  month?: string
  numberBillings?: number
  evidencesNumber?: number
  status?: string
  type?: string
  evidences?: EvidencesByMonth[]
  files?: EvidencesFiles[]
}

export interface Evidences {
  fileId?: string
  name?: string
}

export interface Witnesses {
  provider: string
  mediaType: string
  month: string
  evidences: Evidences[]
}

interface WitnessesProps {
  conciliation?: ConciliationLetter
  witness?: Witnesses
  period?: PeriodList
  index?: number
}

const WitnessesCard = ({ period }: WitnessesProps) => {
  const vaultService = new VaultService()
  const selectedConciliation = useConciliationStore(e => e.selectedConciliation)
  const [evidences, setEvidences] = useState<FileReviewable[]>([])
  const [images, setImages] = useState<string[]>([])

  const handleImg = async (fileId: string, ext: string) => {
    const response = await vaultService.getFile(fileId!)
    if (response) {
      //@ts-ignore
      return `data:image/${ext};base64,${response?.file}`
    } else {
      return ''
    }
  }

  useEffect(() => {
    const loadImages = async () => {
      const ev = selectedConciliation?.witnessList?.filter(e =>
        (e.mediaName === period?.mediaName) &&
        (e.providerName === period?.providerName) &&
        DatesUtil.handlePeriodMonth(e?.targetDate!, period?.month!, period?.year!) &&
        (e.fileExt === 'jpeg' || e.fileExt === 'png' || e.fileExt === 'jpg')
      );

      if (ev?.length! > 0) {
        const arrayImg = await Promise.all(ev!.slice(0, 2).map(e => handleImg(e.fileId!, e.fileExt!)));
        setImages(arrayImg);
      }
      setEvidences(ev!);
    };

    loadImages();
  }, [selectedConciliation, period]);



  return (
    <Box cursor='pointer' className='h-full'>
      <BrandBox className='!p-3 h-full'>
        <Flex gap={1} mb={2}>
          {
            evidences.length === 0 ?
              <AspectRatio maxW='400px' w='100%' ratio={8 / 3}>
                <Text className='bg-gray-300 font-semibold'>Sin evidencias cargadas</Text>
              </AspectRatio>
              :
              <>
                <AspectRatio maxW='400px' w='100%' ratio={(images.length > 1 ? 4 : 8) / 3}>
                  {images[0] ? <Image src={images[0]} objectFit='cover' /> : <Text>Loading...</Text>}
                </AspectRatio>
                {
                  images.length > 1 &&
                  <AspectRatio maxW='400px' w='100%' ratio={4 / 3}>
                    {images[1] ? <Image src={images[1]} objectFit='cover' /> : <Text>Loading...</Text>}
                  </AspectRatio>
                }

              </>
          }
        </Flex>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text color='brand.500' fontWeight='semibold'>{period?.periodName} ({evidences.length})</Text>
          <Text color='gray' fontWeight='semibold'>{period?.mediaName}</Text>
        </Flex>
        <Divider pt={2} />
        <Box pt={2} fontWeight='semibold' justifyContent='space-between'>
          <Text>
            {
              period?.providerName
            }
          </Text>
          {/* <Text>
            Evidencias: 0 {witness?.evidences.length}
          </Text> */}
        </Box>
      </BrandBox>
    </Box>
  )
}

export default WitnessesCard