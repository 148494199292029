import BrandBox from '@/core/components/molecules/BrandBox'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ConciliationLetter } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'
import { useEffect, useState } from 'react'
import { useComponentsStore } from '@/core/stores/components.store'
import { useModalStore } from '@/core/stores/modal.store'
import useMountedEffect from '@/core/hooks/useMountedEffect'
import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage'
import { BiPlus, BiPlusCircle } from 'react-icons/bi'
import ConciliationLetterDocumentView from './ConciliationLetterDocumentView'
import UploadConciliationLetterModal from './UploadConciliationLetterModal'

interface OrderProps {
  conciliation: ConciliationLetter
}

function ConciliationLetterPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { setSelectedLetterProvider, conciliationLetter, selectedConciliation, setConciliationLetter, selectedConciliationLetter } = useConciliationStore();
  const openModal = useModalStore(e => e.openModal);
  const [selectedPdf, setSelectedPdf] = useState<File | null>(null)

  const handleClick = (conciliation: ConciliationLetter) => {
    setSelectedLetterProvider(conciliation.provider)
    navigate('/views/reconciliations/conciliation-letter')
  }

  /* useEffect(() => {
    console.log(selectedConciliationLetter, 'soas')
  }, [selectedConciliationLetter]) */

  useMountedEffect(() => {
    if (uiConciliationComponents.seeOrdersProviders) {
      console.log(selectedPdf)
    } else {
      if (selectedConciliation?.conciliationLetterList && selectedConciliation?.conciliationLetterList.length > 0) {
        const conciliationLetterList = selectedConciliation?.conciliationLetterList;
        const idLast = conciliationLetterList[conciliationLetterList?.length - 1]
        setConciliationLetter(idLast)
      }
    }
  })

  const RowHover = ({ conciliation }: OrderProps) => {

    return <Tr className='group hover:bg-blue-100'>
      <Td>
        <Flex columnGap={6}>
          <Text fontWeight={'bold'}>
            {conciliation.tvType}
          </Text>
          <Text>
            {conciliation.provider}
          </Text>
        </Flex>
      </Td>
      <Td>{conciliation.versions[0].name}</Td>
      <Td isNumeric>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant='ghost'
          className='opacity-0 group-hover:opacity-100 transition-opacity'
          onClick={() => handleClick(conciliation)}
        >Ver carta</Button>
      </Td>
    </Tr>
  }
  return (
    <>
      {
        /* uiConciliationComponents.seeConciliationLetters ?
          selectedConciliation?.conciliationLetterList && selectedConciliation?.conciliationLetterList.length > 0 ?
            <BrandBox>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th fontWeight='bold' color='black' fontSize='sm'>Proveedores</Th>
                      <Th fontWeight='bold' color='black' fontSize='sm'>Nombre del archivo</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      conciliationLetter.map((conciliation, index) => {
                        return <RowHover key={index} conciliation={conciliation} />
                      })
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </BrandBox> :
            <EmptyDataMessage text='No hay proveedores asignados'>
              <Button rightIcon={<BiPlusCircle />} onClick={() => console.log('')} colorScheme="brand">
                Agregar proveedor
              </Button>
            </EmptyDataMessage>
          :
          selectedConciliationLetter ?
            <>
              <ConciliationLetterDocumentView />
            </>
            :
            <EmptyDataMessage text='No existen cartas de conciliación'>
              <Button rightIcon={<BiPlus />} onClick={() => openModal('UploadConciliationLetterModal')} colorScheme="brand">
                Subir carta de conciliación
              </Button>
            </EmptyDataMessage> */
        selectedConciliationLetter ?
          <>
            <ConciliationLetterDocumentView />
          </>
          :
          <EmptyDataMessage text='No existen cartas de conciliación'>
            <Button rightIcon={<BiPlus />} onClick={() => openModal('UploadConciliationLetterModal')} colorScheme="brand">
              Subir carta de conciliación
            </Button>
          </EmptyDataMessage>
      }
      <UploadConciliationLetterModal setSelectedFile={setSelectedPdf} />
    </>
  )
}

export default ConciliationLetterPage