import { useUserStore } from "@/modules/auth/stores/user.store";
import { useConciliationStore } from "@/modules/conciliation/stores/conciliation.store";
import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";

/** Contrato del Store */
interface ComponentsState {
  uiConciliationComponents: {
    showFilterOrderBy: boolean;
    addPurchaseOrders: boolean;
    seeWitnessesProviders: boolean;
    addWitnessesFiles: boolean;
    approveWitnesses: boolean;
    seeConciliationLetters: boolean;
    seeBillingProviders: boolean;
    approveBilling: boolean;
    seeStatusBilling: boolean;
    addBillingFile: boolean;
    seeOrdersProviders: boolean;
  };
  getUiConciliationComponents: () => void
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  uiConciliationComponents: {
    showFilterOrderBy: false,
    addPurchaseOrders: true,
    seeWitnessesProviders: false,
    addWitnessesFiles: true,
    approveWitnesses: false,
    seeConciliationLetters: false,
    seeBillingProviders: false,
    seeOrdersProviders: false,
    approveBilling: false,
    seeStatusBilling: false,
    addBillingFile: true,
  }
}

/** Definición del Store */
const Store: StateCreator<ComponentsState> = (set, get) => ({
  ...initialState,

  getUiConciliationComponents: () => {
    const email = useUserStore.getState().userData?.email
    console.log(email)
    if (email === 'hugues@axeleratum.com' /* || email === 'mauricio-axe@yopmail.com' */) {
      set({
        uiConciliationComponents: {
          showFilterOrderBy: true,
          addPurchaseOrders: false,
          seeWitnessesProviders: true,
          addWitnessesFiles: false,
          approveWitnesses: true,
          seeConciliationLetters: true,
          seeBillingProviders: true,
          seeOrdersProviders: true,
          approveBilling: true,
          seeStatusBilling: true,
          addBillingFile: false,
        }
      })
    }
  },

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useComponentsStore = create<ComponentsState>()(immer(Store))