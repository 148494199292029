import {
    Box,
    Text,
    Skeleton,
    SkeletonText,
    useColorModeValue,
  } from "@chakra-ui/react";
import { ChatHistory } from "./SagaChatContainer";

  interface HistoryChatsProps {
    messages: ChatHistory[]
    selectedDocument: (data: any, metadata: string[]) => Promise<null>
  }
  
  export const HistoryChats = ({ messages = [] }: HistoryChatsProps) => {
    const textEmpyFlag = "-1";
  
    const messageSentBg = "#7678ED";
    const messageReceivedBg = useColorModeValue("#F1F7FF", "gray.700");
    const messageSentTextColor = "#FFFFFF";
    const messageReceivedTextColor = useColorModeValue("black", "white");
  
    return (
      <Box display="flex" flexDirection="column" width="100%" gap="8px">
        <Box display="flex" flexDirection="column" width="100%" gap="8px">
          {messages.map((message, index) => (
            <Box
              key={index}
              display="flex"
              width="100%"
              justifyContent={message.isSender ? "flex-end" : "flex-start"}
            >
              <Box
                maxWidth="70%"
                padding="0.75rem"
                borderRadius="12px"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                bg={message.isSender ? messageSentBg : messageReceivedBg}
                borderBottomRightRadius={message.isSender ? "0px" : "12px"}
                borderBottomLeftRadius={message.isSender ? "12px" : "0px"}
              >
                {!message.isSender && message.image && (
                  <Box as="img" height="90%" width="70%" src={`data:image/png;base64,${message.image}`} alt="message" />
                )}
  
                <Text
                  whiteSpace="pre-wrap"
                  color={message.isSender ? messageSentTextColor : messageReceivedTextColor}
                >
                  {message.secondary === textEmpyFlag ? (
                    <Skeleton>
                      <SkeletonText />
                    </Skeleton>
                  ) : (
                    message.secondary
                  )}
                </Text>
  
                {!message.isSender && message.subdata && (
                  <Text fontStyle="italic" fontSize="sm">
                    {`Procesando: \n ${message.subdata}`}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  