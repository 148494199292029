import { useEffect, useState } from 'react'
import WitnessesFilters from './WitnessesFilters'
import { Box, Button, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import WitnessesCard from './WitnessesCard'
import { useNavigate } from 'react-router-dom'
import { useComponentsStore } from '@/core/stores/components.store'
import { PeriodList } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'
import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage'
import { DatesUtil } from '@/core/utils/dates.util'

function WitnessesPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents, getUiConciliationComponents } = useComponentsStore();
  const { filteredWitness, selectedConciliation, setSelectedWitnessesProvider, setSelectedWitnessesPeriod, setWitnessEvidences } = useConciliationStore();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Número de elementos por página

  // Calcular los elementos de la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWitness?.slice(indexOfFirstItem, indexOfLastItem);

  // Cambiar de página
  const totalPages = Math.ceil(filteredWitness?.length! / itemsPerPage);
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  const handleWitness = (provider: PeriodList) => {
    const sl = selectedConciliation?.witnessList?.filter((e) => e.mediaName === provider?.mediaName && e.providerName === provider?.providerName && DatesUtil.handlePeriodMonth(e?.targetDate!, provider?.month!, provider?.year!))
    setWitnessEvidences(sl!)
    setSelectedWitnessesPeriod(provider)
    navigate('/views/reconciliations/witnesses/evidences')
  }

  useEffect(() => {
    getUiConciliationComponents()
    uiConciliationComponents.seeWitnessesProviders ? setSelectedWitnessesProvider('') : setSelectedWitnessesProvider('TV AZTECA')
  }, [])
  return (
    <>
      <WitnessesFilters />
      {
        filteredWitness?.length! > 0 ?
          <>
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
              {
                currentItems?.map((period) => (
                  <Box key={`${period.periodName}-${period.providerName}-${period.mediaName}`} onClick={() => handleWitness(period)}>
                    <WitnessesCard period={period} />
                  </Box>
                ))
              }
            </SimpleGrid>
            {/* Controles de paginación */}
            <HStack spacing={4} mt={6} justify="end">
              <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>
                Anterior
              </Button>
              <Text>
                Página {currentPage} de {totalPages}
              </Text>
              <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </HStack>
          </>
          :
          <EmptyDataMessage text='No se encontraron coincidencias' />
      }
    </>
  )
}

export default WitnessesPage