import AmountIndicator from '@/modules/graphics/components/AmountIndicator'
import MediosBarChart from '@/modules/graphics/components/MediosBarChart'
import MediosCompareChart from '@/modules/graphics/components/MediosCompareChart'
import MediosRadialChart from '@/modules/graphics/components/MediosRadialChart'
import { MediosBarData } from '@/modules/graphics/data/medios-bars.data'
import { MediosCompareData } from '@/modules/graphics/data/medios-compare.data'
import { Grid, GridItem } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { TbAccessPoint, TbActivity, TbCurrencyDollar, TbDeviceTv, TbFileText, TbPhoto } from 'react-icons/tb'
import { useCampaignStore } from '../store/campaign.store'

function SummaryPage() {
  const { selectedCampaign } = useCampaignStore();

  const getIndicatorIcon = (type: string): IconType => {
    switch (type) {
      case "AMOUNT": return TbCurrencyDollar
      case "TV": return TbDeviceTv
      case "RADIO": return TbAccessPoint
      case "IMAGE": return TbPhoto
      case "DOCUMENT": return TbFileText
      default: return TbActivity
    }
  }
  /** Graphics data */
  const mediosCompare1 = MediosCompareData
  const mediosRadial = [
    { firstLabel: 'Inversión', firstQuantity: 2030000.00, secondLabel: 'Bonificable', secondQuantity: 952000.00 }
  ]
  const mediosBarData = MediosBarData

  return (
    <div className="flex flex-col gap-4">
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, md: 3 }}>
          <AmountIndicator
            icon={getIndicatorIcon('AMOUNT')}
            filterText={'Octubre'}
            label={'Gran total'}
            amount={selectedCampaign?.budget!}
            currency="$"
            isActive
          />
        </GridItem>
        {
          selectedCampaign?.mediaList?.map((e, i) => (
            <GridItem key={e.id} colSpan={{ base: 12, md: 3 }}>
              <AmountIndicator
                icon={getIndicatorIcon('TV')}
                filterText={'Octubre'}
                label={e.name}
                amount={e.amount}
                currency="$"
              />
            </GridItem>
          ))
        }
        {/* {amountIndicators.map((indicator, index) => (
                      <GridItem key={index} colSpan={{ base: 12, md: 3 }}>
                        <AmountIndicator
                          icon={getIndicatorIcon(indicator.type)}
                          filterText={indicator.filterBy}
                          label={indicator.description}
                          amount={indicator.qantity}
                          currency="$"
                          isActive={index === 0 ? true : false}
                        />
                      </GridItem>
                    ))} */}
      </Grid>
      {/* <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <MediosCompareChart
            label="TV abierta (regular)"
            amount={3858698.02}
            currency="$"
            nsColor="#5D8DEB"
            prColor="#0344DC"
            data={mediosCompare1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <MediosCompareChart
            label="TV abierta (Key Properties)"
            amount={3858698.02}
            currency="$"
            nsColor="#0BC5EA"
            prColor="#0987A0"
            data={mediosCompare1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <MediosRadialChart
            label="Estadios Televisivos"
            data={mediosRadial}
            nsColor="#0344DC"
            prColor="#F6E05E"
          />
        </GridItem>
      </Grid> */}
      {/* Bars */}
      {/* <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="TV Paga (Regular)"
            amounts={mediosBarData.medios1.amounts}
            barName="No. Spots"
            barColor="#3266ED"
            data={mediosBarData.medios1.data}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="TV Paga (Patrocinios Key Properties)"
            amounts={mediosBarData.medios2.amounts}
            barName="No. Spots"
            barColor="#0031C5"
            data={mediosBarData.medios2.data}
          />
        </GridItem> */}
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="Todos los medios"
            amounts={[
              { name: 'Inversión', amount: selectedCampaign?.budget!, currency: "$" }
            ]}
            barName="No. de interacciones"
            barColor="#805AD5"
            data={mediosBarData.medios3.data}
          />
        </GridItem>
        {
          selectedCampaign?.mediaList?.map((e, i) => (
            <GridItem key={e.id} colSpan={{ base: 12, md: 3 }}>
              <MediosBarChart
                label={e.name}
                amounts={[
                  { name: 'Inversión', amount: e.amount!, currency: "$" }
                ]}
                barName="No. de interacciones"
                barColor="#805AD5"
                data={mediosBarData.medios3.data}
              />
            </GridItem>
          ))
        }
        {/* <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="TV Local (AAEE)"
            amounts={mediosBarData.medios4.amounts}
            barName="No. de interacciones"
            barColor="#553C9A"
            data={mediosBarData.medios4.data}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="Radio (Pauta regular)"
            amounts={mediosBarData.medios5.amounts}
            barName="No. de interacciones"
            barColor="#22C677"
            data={mediosBarData.medios5.data}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <MediosBarChart
            label="Radio (AAEE)"
            amounts={mediosBarData.medios6.amounts}
            barName="No. de interacciones"
            barColor="#22C677"
            data={mediosBarData.medios6.data}
          />
        </GridItem> */}
      </Grid>
    </div>
  )
}

export default SummaryPage