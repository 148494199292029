import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CommentsPanel from './CommentsPanel'
import { useCampaignStore } from '../store/campaign.store';
import useMountedEffect from '@/core/hooks/useMountedEffect';
import { VaultService } from '@/core/services/vault.service';
import { CampaignItem, Comment } from '../models/campaign.model';

function StrategyPage() {
  const vaultService = new VaultService();
  const { selectedCampaign, updateCampaignStrategyComments } = useCampaignStore()
  const [pdfSrc, setPdfSrc] = useState<string | null>(null);

  const handleComments = async (comments: Comment[]) => {
    let body: CampaignItem = {
      ...selectedCampaign,
      strategyFileList: [
        {
          ...selectedCampaign?.strategyFileList?.[0],
          comments: comments ?? []
        }

      ]
    }
    console.log(body);
    updateCampaignStrategyComments(body).then(
      response => {
        console.log(response)
      }
    )
  }

  useMountedEffect(() => {
    vaultService.getFile(selectedCampaign?.strategyFileList?.[0].fileId!).then(
      response => {
        //@ts-ignore
        setPdfSrc(response.file)
      }
    )
  })

  return (
    <Flex>
      <Box w={'70%'}>
        {
          pdfSrc && (
            <object type="application/pdf" data={`data:application/pdf;base64,${pdfSrc}`} width="100%" height="100%">
              <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={`data:application/pdf;base64,${pdfSrc}`}>Descargar PDF</a>.</p>
            </object>
          )
        }
      </Box>
      <CommentsPanel comments={selectedCampaign?.strategyFileList?.[0].comments} sendComments={handleComments} />
    </Flex>
  )
}

export default StrategyPage