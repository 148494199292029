import { Box, Button, Flex, FormControl, FormErrorMessage, Input, InputGroup, Select, Text } from "@chakra-ui/react"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useConciliationStore } from "../stores/conciliation.store"
import { useFormik } from "formik"
import { DataUtil } from "@/core/utils/data.util"
import CustomModal from "@/core/components/organism/CustomModal"
import { useModalStore } from "@/core/stores/modal.store"

import * as Yup from "yup"

const ApprovePurchaseOrdersModal: React.FC = () => {
  const toast = useCustomToast()
  const closeModal = useModalStore(e => e.closeModal)
  const { selectedConciliation, selectedPurchaseOrder, insertPurchaseOrderMount  } = useConciliationStore()


  const handleApprove = () => {
    const mount = approveForm.values.selectAmount === 'otro' ? approveForm.values.amount : approveForm.values.selectAmount
    insertPurchaseOrderMount(selectedConciliation?.id!, selectedPurchaseOrder?.fileId!, parseFloat(mount)).then(() => {
      toast.success(`Órden de compra aprobada con un monto de: ${mount}`)
    }).catch((error) => {
      toast.error(`Ha ocurrido un error al aprobar`)
      console.error(error)
    })
    closeModal('ApprovePurchaseOrdersModal')
  }

  const approveForm = useFormik({
    initialValues: {
      selectAmount: '',
      amount: '',
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      selectAmount: Yup.string().required("Este campo es requerido"),
      amount: Yup.string().when('selectAmount', (selectAmount, schema) => {
        return Array.isArray(selectAmount) && selectAmount.includes('otro')
          ? schema.required("Este campo es requerido")
          : schema;
      }),
    }),       
    onSubmit: handleApprove,
  });
  

  return (
    <CustomModal
      id="ApprovePurchaseOrdersModal"
      title="Aprobar carta de conciliación"
      cancelText="Cancelar"
      acceptText="Aprobar"
      size="md"
      closeOnAccept={false}
      withButtons={false}
    >
      {() => (
        <Box>
          <Text>Para aprobar seleccione la cantidad correspondiente al monto total:</Text>
          <Box>
            <Select
              placeholder='Selecciona una cantidad'
              bg="white"
              borderRadius="md"
              id="selectAmount"
              value={approveForm.values.selectAmount}
              onChange={approveForm.handleChange}
            >
              {
                selectedPurchaseOrder?.amountList?.map((e, i) => (
                  <option key={i} value={e}>{DataUtil.numberCurrencyFormat(e)}</option>
                )).concat(
                  <option key='otro' value='otro'>Otro</option>
                )
              }

            </Select>
            {
              approveForm.values.selectAmount === 'otro' &&
              <FormControl isInvalid={!!approveForm.errors.amount}>
                <label htmlFor="amount" className="text-sm leading-8">
                  Ingrese una cantidad
                </label>
                <InputGroup>
                  <Input
                    id="amount"
                    name="amount"
                    type="text"
                    onChange={approveForm.handleChange}
                    value={approveForm.values.amount}
                  />
                </InputGroup>
                <FormErrorMessage>{approveForm.errors.amount}</FormErrorMessage>
              </FormControl>
            }
          </Box>
          <Flex justifyContent='end' gap={3} mt={3}>
            <Button variant='outline' onClick={() => closeModal('ApprovePurchaseOrdersModal')}>Cancelar</Button>
            <Button onClick={() => approveForm.handleSubmit()}>Aprobar</Button>
          </Flex>
        </Box>
      )}
    </CustomModal>
  )
}

export default ApprovePurchaseOrdersModal