import { useUserStore } from "@/modules/auth/stores/user.store"
import { useAdminUserGroupStore } from "../stores/admin-user-group.store"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import { TbFilterSearch, TbUsersPlus } from "react-icons/tb"
import { useModalStore } from "@/core/stores/modal.store"
import { useMemo, useState } from "react"
import { Box, Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import CreateOrEditUserGroupModal from "../components/user-groups/CreateOrEditUserGroupModal"
import ConfirmUserGroupDeletionAlert from "../components/user-groups/ConfirmUserGroupDeletionAlert"
import UserGroupCards from "../components/user-groups/UserGroupCards"
import { useAdminUserStore } from "../stores/admin-user.store"
import ConfirmUserDeletionOnGroup from "../components/user-groups/ConfirmUserDeletionOnGroupAlert"


const UserGroupsPage: React.FC = ( ) => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const userGroups = useAdminUserGroupStore( s => s.userGroups )
    const { retrieveUserGroups } = useAdminUserGroupStore()
    const { retrieveUsers } = useAdminUserStore()
    const openModal = useModalStore( s => s.openModal )
    const [ inputFilter, setInputFilter] = useState<string>('')

    const handleNewGroup = () => {
        openModal( 'createOrEditUserGroupModal' )
    }

    const filterUserGroups = useMemo( () => {
        const filteredUsersByInput = userGroups?.filter( item => {
            const searchString = `${item.name} ${item.description}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }, [ userGroups, inputFilter ])

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    useMountedEffect( () => {
        retrieveUserGroups( companyData?.id! )
        retrieveUsers( companyData?.id! )
    })

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-end">
                <div className="flex items-center gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    <Box>
                        <Button
                            leftIcon={<TbUsersPlus />}
                            colorScheme="brand"
                            onClick={handleNewGroup}>
                            Nuevo grupo
                        </Button>
                    </Box>
                </div>
            </div>
            {/* Datos */}
            <UserGroupCards
                data={filterUserGroups}
            />
            {/* Modals | Alerts */}
            <CreateOrEditUserGroupModal />
            <ConfirmUserGroupDeletionAlert />
            <ConfirmUserDeletionOnGroup />
        </article>
    )
}

export default UserGroupsPage