import SummaryPage from "@/modules/campaigns/components/SummaryPage"
import MediaPage from "@/modules/campaigns/components/MediaPage"
import { RouteObject } from "react-router-dom"
import { useCampaignStore } from "@/modules/campaigns/store/campaign.store"


const FlowSectionRoutes: RouteObject[] = [
    { path: 'summary', element: <SummaryPage /> },
    { path: 'media', element: <MediaPage key={useCampaignStore.getState().selectedFlowMedia?.name} /> },
]

export default FlowSectionRoutes