import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Binnacle } from "../models/binnacle.model";

interface FiltersComments {
  autor?: string
  version?: string
  date?: string
}

/** Contrato del Store */
interface BinnacleState {
  comments: Binnacle[]
  filteredComments: Binnacle[]
  autors: string[]
  versions: string[]
  filters: FiltersComments
  setComments: (tasks: Binnacle[]) => void
  setFilters: (filters: FiltersComments) => void
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  comments: [],
  autors: [],
  versions: [],
  filteredComments: [],
  filters: {}
}

/** Definición del Store */
const Store: StateCreator<BinnacleState> = (set, get) => ({
  ...initialState,

  setComments: (comments: Binnacle[]) => {
    const ver = comments.map(e => e.version);
    const autor = comments.map(e => e.autor)
    const versionArray = removeDuplicates(ver as string[]);
    const autorArray = removeDuplicates(autor as string[])
    set({ comments: comments, filteredComments: comments, versions: versionArray, autors: autorArray })
  },

  setFilters: (filters: FiltersComments) => {
    const binacleFiltered = get().comments.filter(comment => {
      const matchesAutor = filters.autor ? comment.autor === filters.autor : true;
      const matchesVersion = filters.version ? comment.version === filters.version : true;
      const matchesDate = filters.date ? (comment.date ? comment.date.toISOString().split('T')[0] === filters.date : false) : true;
      return matchesAutor && matchesVersion && matchesDate;
    });
    set({filters: filters, filteredComments: binacleFiltered})
  },

  reset: () => set(initialState)

})

function removeDuplicates(array: string[] | undefined): string[] | undefined {
  return array?.reduce((accumulator: string[], currentValue: string) => {
    if (!accumulator.includes(currentValue)) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);
}

/** Exportación del Store */
export const useBinnacleStore = create<BinnacleState>()(immer(Store))