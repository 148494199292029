import React, { useEffect, useState } from 'react';
import { Box, Select } from '@chakra-ui/react';
import { useConciliationStore } from '../stores/conciliation.store';

interface Filters {
  provider: string;
  media: string;
  month: string;
}

const WitnessesFilters = () => {
  const { getDataFiltered, selectedConciliation } = useConciliationStore()
  const [providers, setProviders] = useState<string[]>([])
  const [media, setMedia] = useState<string[]>([])
  const [months, setMonths] = useState<string[]>([])
  const [filters, setFilters] = useState<Filters>({
    provider: '',
    media: '',
    month: ''
  });

  // Funciones para manejar los cambios de los selectores
  const handleProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, provider: event.target.value });
  };

  const handleMediaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, media: event.target.value });
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({ ...filters, month: event.target.value });
  };


  const repeatDelete = (data: string[]) => {
    return data.reduce((acc: string[], current) => {
      if (!acc.includes(current)) {
        acc.push(current);
      }
      return acc;
    }, [] as string[]);
  }

  useEffect(() => {
    if (selectedConciliation) {
      const prov = selectedConciliation?.periodList?.map(e => e.providerName)
      const med = selectedConciliation?.periodList?.map(e => e.mediaName)
      const mon = selectedConciliation?.periodList?.map(e => e.periodName)
      setProviders(repeatDelete(prov!))
      setMedia(repeatDelete(med!))
      setMonths(repeatDelete(mon!))
    }
  }, [selectedConciliation])

  /*   useMountedEffect(() => {
      
    }) */

  useEffect(() => {
    getDataFiltered(filters)
  }, [filters])

  return (
    <Box display="flex" alignItems="center" gap="4" w={'100%'} mb={3}>
      <Select key='providers' placeholder="Todos los proveedores" bg="white"
        borderRadius="md"
        onChange={handleProviderChange}
        w={'20%'}>
        {
          providers.map((e, index) => (
            <option key={index} value={e}>{e}</option>
          ))
        }
      </Select>
      <Select key='medias' placeholder="Todos los medios" bg="white"
        borderRadius="md"
        onChange={handleMediaChange}
        w={'20%'}>
        {
          media.map((e, index) => (
            <option key={index} value={e}>{e}</option>
          ))
        }
      </Select>
      <Select key='months' placeholder="Todos los meses" bg="white"
        borderRadius="md"
        onChange={handleMonthChange}
        w={'20%'}>
        {
          months.map((e, index) => (
            <option key={index} value={e}>{e}</option>
          ))
        }
      </Select>
    </Box>
  );
};

export default WitnessesFilters;
