import React, { useEffect, useState } from 'react';
import { Select, Button, Flex, Box } from '@chakra-ui/react';
import { BiClipboard, BiCloudUpload, BiDownload, BiSave } from 'react-icons/bi';
import { LuPanelLeft } from 'react-icons/lu';
import LoadFileModal from './LoadFileModal';
import { useModalStore } from '@/core/stores/modal.store';
import { useExcelTableStore } from '@/core/stores/excel-table.store';
import BinnacleModal from '@/modules/planning/components/BinnacleModal';
import { useNavigate } from 'react-router-dom';
import { useCampaignStore } from '../store/campaign.store';
import { GiGears } from 'react-icons/gi';
import SaveExcelFileAlertModal from './SaveExcelFileAlertModal';
import useCustomToast from '@/core/hooks/useCustomToast';
import MediasBuildModal from './MediasBuildModal';

const FlowFiltersSections = () => {
  const toast = useCustomToast()
  const [selectedVersion, setSelectedVersion] = useState<string>('')
  const { save, openFlowFile, selectedFile } = useExcelTableStore()
  const { selectedFlowMedia, getMediaFlowFile } = useCampaignStore()
  const openModal = useModalStore(s => s.openModal)
  const navigate = useNavigate()
  /* const providers = ['Proveedor 1', 'Proveedor 2', 'Proveedor 3']
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ] */

  const handleSelectChange = (value: string) => {
    if (value) {
      setSelectedVersion(value);
      const name = selectedFlowMedia?.fileVersionList.find(e => e.fileId === value)?.version
      getMediaFlowFile(value, name!).then(
        () => { }
      ).catch(
        (e) => {
          toast.error('Ocurrió un error al obtener documento')
        }
      )
    }
  };

  const handleBuildOpenModal = () => {
    openFlowFile()
    openModal('MediasBuildModal')
  }

  /* useMountedEffect(() => {
    reset()
  }) */

  useEffect(() => {
    const versionList = selectedFlowMedia?.fileVersionList
    const lastVersion = versionList && versionList?.length > 0 ? versionList[versionList.length - 1].fileId : ''
    handleSelectChange(lastVersion)
  }, [selectedFlowMedia])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" gap={4}>
        <Flex gap={4} className='w-[55%]'>
          <Select
            className='w-[55%]'
            variant='white'
            placeholder="Selecciona una versión"
            /* defaultValue={defaultSelect} */
            value={selectedVersion}
            onChange={(e) => handleSelectChange(e.target.value)}
          >
            {selectedFlowMedia?.fileVersionList?.map((version) => (
              <option key={version.fileId} value={version.fileId}>{version.version}</option>
            ))}
          </Select>
          {/* <Select variant='white' placeholder="Selecciona un proveedor">
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </Select>
          <Select variant='white' placeholder="Selecciona un mes">
            {months.map((month, index) => (
              <option key={index} value={month.toLowerCase()}>{month}</option>
            ))}
          </Select> */}
        </Flex>
        <Flex gap={4}>
          <Button leftIcon={<LuPanelLeft />} variant="outline" onClick={() => navigate(`/views/campaigns/detail/flow/media/compare`)}>Comparar</Button>
          <Button leftIcon={<BiClipboard />} variant="outline" onClick={() => openModal('BinnacleModal')}>Bitácora</Button>
          <Button rightIcon={<BiCloudUpload />} onClick={() => openModal('LoadFileModal')} colorScheme="brand" isDisabled={selectedFlowMedia?.nodeList?.length! !== 0}>
            Cargar documento
          </Button>
        </Flex>
        <LoadFileModal />
        <BinnacleModal selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion} />
      </Flex>
      <Box display={'flex'} justifyContent={'space-between'} mt={3}>
        <Button onClick={() => save()} leftIcon={<BiDownload />} isDisabled={!!!(selectedFlowMedia?.fileVersionList.find(e => e.fileId === selectedVersion) && selectedFile)}>
          Descargar
        </Button>
        <Flex gap={3}>
          {
            (selectedFile && selectedVersion && selectedFlowMedia?.nodeList?.length! === 0) &&
            <Button onClick={handleBuildOpenModal} leftIcon={<GiGears />} >
              Construir medio
            </Button>
          }
          <Button onClick={() => openModal('SaveExcelFileAlertModal')} leftIcon={<BiSave />} isDisabled={!!!(selectedFile)} >
            Guardar cambios
          </Button>
        </Flex>
      </Box>
      <SaveExcelFileAlertModal />
      <MediasBuildModal />
    </>
  );
};

export default FlowFiltersSections;
