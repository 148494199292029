import { create } from 'zustand';

// Definimos el store usando zustand
interface ArrayStore {
  chatOpen: boolean;
  chatOpenFromPDF: boolean;
  chatId: string | null; // Puedes cambiar el tipo según lo que quieras guardar
  changeChatState: (value: boolean) => void;
  changeChatOpenFromPDF: (value: boolean) => void;
  setChatId: (id: string) => void;
}

export const useChatState = create<ArrayStore>((set) => ({
  chatOpen: false,
  chatOpenFromPDF: false,
  chatId: null,
  changeChatState: (value) => set(() => {
    return { chatOpen: value };
  }),
  changeChatOpenFromPDF: (value: boolean) => set(() => {
    return { chatOpenFromPDF: value };
  }),
  setChatId: (id: string) => set({ chatId: id }),
}));
