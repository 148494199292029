export enum CampaignStatus {
  Actived = 'ACTIVA',
  Closed = 'CERRADA',
  Progress = 'PROCESO',
}

export enum CurrentStatus {
  aprobado = 'aprobado',
  rechazado = 'rechazado',
  revision = 'revision'
}