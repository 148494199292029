import folderService from "@/core/services/saga/folder_service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Thunk to fetch the main vault
export const fetchMainVault = createAsyncThunk(
    "vault/fetchMainVault",
    async ({ companyId, page, folderId = null }, thunkAPI) => {
        try {
            if (folderId === null) {
                const mainVault = await folderService.getMainFolder(companyId, page);
                localStorage.setItem("folderId", mainVault.data.id)
                return mainVault
            } else {

                const vaul = await folderService.getFolder(folderId, companyId, page)

                return vaul;

            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Thunk to select a document
export const selectDocument = createAsyncThunk(
    "vault/selectDocument",
    async (payload, thunkAPI) => {

        try {
            const document = payload.document;
            
            return document;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Slice for vault
export const vaultSlice = createSlice({
    name: "vault",
    initialState: {
        vault: null,
        document: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMainVault.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchMainVault.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vault = action.payload;
            })
            .addCase(fetchMainVault.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            })
            .addCase(selectDocument.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(selectDocument.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.document = action.payload;
            })
            .addCase(selectDocument.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            });
    },
});

export default vaultSlice.reducer;