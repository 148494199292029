const en = {
  global: {
    //words
    login_welcome: 'Welcome to OrkestaMedia',
    login_password: 'Password',
    login_btn1_text: 'Login',
    login_btn2_text: 'I forgot my password',
    welcome_hi: 'Hi',
    welcome_desc: 'We identify these accesses for your account type',
    area_title: 'Area',
    create_area: 'Create area',
    required_file_area: 'The file for the area is required',
    input_area_placeholder: 'Enter the name of the area',
    create_button_text: 'Create',
    enter_button_text: 'Enter',
    file_drop_title: 'You can drag and drop a file here',
    file_drop_title_or: 'or',
    file_drop_button_text: 'Select file by clicking here',
    logout_text: 'Logout',
    admin_text: 'Administration',
    indicator_text1: 'Total campaigns',
    indicator_text2: 'Active campaigns',
    indicator_text3: 'Total budgets',
    indicator_text4: 'Active budgets',
    dashboard_updates: 'Updates',
    dashboard_members: 'Members',
    dashboard_news_not: 'News',
    dashboard_total_budget: 'Total budget',
    dashboard_consumption: 'Consumption budget',
    dashboard_consumption_by_media: 'Consumption by media',
  },
};
export default en;

export type TranslationObject = typeof en;
export type TranslationKeys = keyof typeof en.global;
