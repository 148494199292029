import { Apis } from "@/apis"
import { PlanningStatus } from "@/core/enums/planning.enum"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { BudgetCard, Planning, RoadMap, SubSectionList } from "@/core/models/planning.model"
import { BudgetOrdererDataProps } from "../store/planning.store"

export class PlanningService {
  /** */
  private http = new HttpClient(`${Apis.ORKESTAMEDIA}/api/v1/planningSection`)

  public getPlanningBySection(sectionId: string): Promise<GenericApiResponse> {
    return this.http.get(`/sectionId/${sectionId}`)
  }

  public updatePlanning(body: Planning): Promise<GenericApiResponse> {
    return this.http.patch('', body)
  }

  public createPlanning(body: Planning): Promise<GenericApiResponse> {
    return this.http.put('', body)
  }

  public insertMediaListNodes(planningSectionId: string, body: BudgetOrdererDataProps[]): Promise<GenericApiResponse> {
    return this.http.put(`/${planningSectionId}/mediaList`, body)
  }

  public updateBudgetCardPlanning(planningSectionId: string, body: BudgetCard[]): Promise<GenericApiResponse> {
    return this.http.patch(`/${planningSectionId}/budgetCardList`, body)
  }

  public updateRoadMapPlanning(planningSectionId: string, items: RoadMap[]): Promise<GenericApiResponse> {
    return this.http.patch(`/${planningSectionId}/roudMapList`, items)
  }

  public updateStatusPlanning(planningSectionId: string, status: PlanningStatus): Promise<GenericApiResponse> {
    return this.http.patch(`/${planningSectionId}/status/${status}`, {})
  }
  
  public budgetCardVault(planningSectionId: string, temporalIdVault: string): Promise<GenericApiResponse> {
    return this.http.put(`/${planningSectionId}/budgetCard/${temporalIdVault}`, {})
  }

  public updatePlanningSubSection(planningSectionId: string, items: SubSectionList): Promise<GenericApiResponse> {
    return this.http.patch(`/${planningSectionId}/subSection`, items)
  }

  public updatePlanningSubSectionList(planningSectionId: string, items: SubSectionList[]): Promise<GenericApiResponse> {
    return this.http.patch(`/${planningSectionId}/subSectionList`, items)
  }

}