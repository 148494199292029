import { TranslationKeys } from '@/i18n/en'
import { Avatar, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal, useTheme } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { BiChevronDown } from "react-icons/bi"
import { FiLogOut } from "react-icons/fi"
import { FiSettings } from "react-icons/fi"
import { useNavigate } from 'react-router-dom'

interface UserMenuProps {
    userName: string
    signOut: () => void
}

const UserMenu: React.FC<UserMenuProps> = ({ userName, signOut }) => {
    /* configuración lenguaje */
    const { i18n } = useTranslation("global");
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [currentLang, setCurrentLang] = useState(localStorage.getItem("lang") ?? "es");

    const handleChangeLang = (lang: "es" | "en") => {
        setCurrentLang(lang);
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    };
    /** */
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <section className="user-menu">
            <Menu>
                <MenuButton style={{ all: "unset", display: "block", cursor: "pointer", paddingLeft: "1rem", borderLeft: "1px solid gray" }}>
                    <div className="flex items-center gap-2">
                        <Avatar name={userName}
                            background={theme.colors.brand[500]}
                            color={theme.colors.brand[50]}
                        />
                        <BiChevronDown size={20} />
                    </div>
                </MenuButton>
                <Portal>
                    <MenuList shadow={"2xl"} zIndex="modal" style={{ minWidth: "unset" }}>
                        <div className="w-[200px] px-2 max-h-[70vh] overflow-y-auto relative">
                            <div className="w-full flex justify-between items-center text-lg font-medium text-brand-600 border-b p-2 sticky top-0 bg-white">
                                <span>{userName}</span>
                            </div>
                            {/* <MenuDivider /> */}
                            <UserMenuItem
                                label={t('admin_text')}
                                icon={FiSettings}
                                onAction={() => navigate('/views/admin')}
                            />
                            <Flex justifyContent={'space-between'} columnGap={2}>
                                <Button variant={currentLang === 'es' ? 'outline' : 'solid'} onClick={() => handleChangeLang("es")} width={'100%'}>Es</Button>
                                <Button variant={currentLang === 'en' ? 'outline' : 'solid'} onClick={() => handleChangeLang("en")} width={'100%'}>En</Button>
                            </Flex>
                            <UserMenuItem
                                label={t('logout_text')}
                                icon={FiLogOut}
                                onAction={signOut}
                            />
                        </div>
                    </MenuList>
                </Portal>
            </Menu>
        </section>
    )
}

interface UserMenuItemProps {
    label: string
    icon: IconType
    onAction: () => void
}

const UserMenuItem: React.FC<UserMenuItemProps> = ({ label, icon: Icon, onAction }) => {
    return (
        <MenuItem style={{ all: "unset", width: "100%", marginTop: "0.5rem" }} onClick={onAction}>
            <div className="w-full p-2 flex gap-2 items-center justify-between cursor-pointer rounded-lg hover:bg-brand-50 hover:text-brand-500">
                <p>{label}</p>
                <Icon size={20} color="inherit" />
            </div>
        </MenuItem>
    )
}

export default UserMenu