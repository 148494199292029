import { Badge, Button, Flex, Select, Text } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { useConciliationStore } from '../stores/conciliation.store'
import { getColorBadgeV2 } from '@/modules/planning/hooks/useGetColorBadge'
import { BiCloudUpload } from 'react-icons/bi'
import { useModalStore } from '@/core/stores/modal.store'
import { CheckIcon } from '@chakra-ui/icons'
import ApproveConciliationLetterModal from './ApproveConciliationLetterModal'
import { DataUtil } from '@/core/utils/data.util'

interface SelectLetterProps {
  letterSelected: string
  setLetterSelected: (letterSelected: string) => void
}

const ConciliationLetterHeadVersionsStatus = ({ letterSelected, setLetterSelected }: SelectLetterProps) => {
  const openModal = useModalStore(e => e.openModal)
  const { selectedConciliation, selectedConciliationLetter, setConciliationLetter } = useConciliationStore()

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLetterSelected(e.target.value)
    const selectedVersion = selectedConciliation?.conciliationLetterList?.find(version => version.fileId === e.target.value)
    if (selectedVersion) {
      setConciliationLetter(selectedVersion)
    }
  }

  return (
    <Flex justifyContent='space-between' alignItems='center' mb={3}>
      <Select
        placeholder='Selecciona una versión'
        bg="white"
        borderRadius="md"
        w={'20%'}
        value={letterSelected}
        onChange={handleChange}
      >
        {
          selectedConciliation?.conciliationLetterList?.map((e, i) => (
            <option key={e.fileId} value={e.fileId}>Carta de conciliación {i + 1}</option>
          ))
        }
      </Select>
      <Flex alignItems='center' gap={3}>
        <Text fontWeight='bold'>Estatus: </Text> <Badge rounded={10} colorScheme={getColorBadgeV2(selectedConciliationLetter?.currentStatus)}>{selectedConciliationLetter?.currentStatus}</Badge>
        <Text fontWeight='bold'>Monto: </Text> {selectedConciliationLetter?.amount ? DataUtil.numberCurrencyFormat(selectedConciliationLetter?.amount!) : 'Sin aprobar'}
      </Flex>
      <Flex gap={3}>
        <Button onClick={() => openModal('UploadConciliationLetterModal')} colorScheme="brand" rightIcon={<BiCloudUpload />}>
          Cargar carta de conciliación
        </Button>
        {
          (selectedConciliationLetter && selectedConciliationLetter?.amount === 0) && <Button onClick={() => openModal('ApproveConciliationLetterModal')} colorScheme="brand" rightIcon={<CheckIcon />}>
            Aprobar
          </Button>
        }
      </Flex>
      <ApproveConciliationLetterModal />
    </Flex>
  )
}

export default ConciliationLetterHeadVersionsStatus