import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import WitnessesCards from '../components/WitnessesCards';
import { useNavigate } from 'react-router-dom';
import { useComponentsStore } from '@/core/stores/components.store';
import { EvidencesByMonth } from '../models/conciliation.model';
import { useConciliationStore } from '../stores/conciliation.store';
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store';
import WinessesAddFolderModal from '../components/WinessesAddFolderModal';
import useMountedEffect from '@/core/hooks/useMountedEffect';
import { useEffect } from 'react';

function ConciliationWitnessesPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const { selectedWitnessesProvider, setSelectedWitnessesMonth, selectedConciliation, selectedWitnessesPeriod } = useConciliationStore()
  const { selectedCampaign } = useCampaignStore()

  const handleWitnessMonths = (month: string) => {
    setSelectedWitnessesMonth(month)
    navigate('/views/reconciliations/witnesses/evidences')
  }

  useMountedEffect(() => {
    console.log(selectedConciliation, selectedWitnessesProvider, 'conciliacion');
    const sl = selectedConciliation?.witness?.filter((e) => ((e.mediaName === selectedWitnessesPeriod?.mediaName) && (e.providerName == selectedWitnessesPeriod?.providerName) && handlePeriodMonth(e?.targetDate!, selectedWitnessesPeriod?.month!, selectedWitnessesPeriod?.year!)))
    console.log(sl)
  })

  const handlePeriodMonth = (date?: string, month?: number, year?: number) => {
    const dateData = new Date(date!)
    return (dateData.getMonth() === month && dateData.getFullYear() === year)
  }

  useMountedEffect(() => {
    const sl = selectedConciliation?.witness?.find((e) => ((e.mediaName === selectedWitnessesPeriod?.mediaName) && (e.providerName == selectedWitnessesPeriod?.providerName) && handlePeriodMonth(e?.targetDate!, selectedWitnessesPeriod?.month!, selectedWitnessesPeriod?.year!)))
  })

  useEffect(() => {
    if (selectedWitnessesPeriod) {
      const sl = selectedConciliation?.witness?.find((e) => ((e.mediaName === selectedWitnessesPeriod?.mediaName) && (e.providerName == selectedWitnessesPeriod?.providerName) && handlePeriodMonth(e?.targetDate!, selectedWitnessesPeriod?.month!, selectedWitnessesPeriod?.year!)))
    }
  }, [selectedWitnessesPeriod])

  return (
    <>
      {uiConciliationComponents.seeWitnessesProviders && <BrandPageHead title={`${selectedWitnessesPeriod?.periodName} - ${selectedWitnessesPeriod?.providerName} - ${selectedWitnessesPeriod?.mediaName}`} subtitle={selectedWitnessesProvider?.provider} >
        {/* <Flex gap={2} alignItems={'center'}>
          <Text fontWeight="bold">Campaña:</Text>
          <Text></Text>
        </Flex> */}
      </BrandPageHead>}
      <Flex justifyContent='space-between'>
        <Text fontSize='lg' fontWeight='semibold'>Carpetas de evidencias</Text>
        {/* <Button onClick={() => openModal('WinessesAddFolderModal')}>Crear carpeta</Button> */}
      </Flex>
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
        {
          selectedWitnessesProvider?.evidences?.map((conciliation: EvidencesByMonth, index: number) => (
            <Box onClick={() => handleWitnessMonths(conciliation.month!)} key={index}>
              <WitnessesCards conciliation={conciliation} index={index} />
            </Box>
          ))
        }
      </SimpleGrid>
      <WinessesAddFolderModal />
    </>
  )
}

export default ConciliationWitnessesPage