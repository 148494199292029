export enum PlanningStatus {
  Actived = 'ACTIVA',
  Closed = 'CERRADA',
  Progress = 'PROCESO',
}

export enum TypeSubSection {
  Bonus = 'BONUS',
  Budgetcard = 'BUDGETCARD',
  Negotiation = 'NEGOTIATION',
  RoadMap = 'ROADMAP'
}