import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import chakraTheme from '@/theme/chakra-theme'
import Router from '@/routes/Router'
import Loader from '@/core/components/molecules/Loader'
import RestrictedActionModal from '@/core/components/organism/RestrictedActionModal'
import { I18nextProvider } from 'react-i18next'
import i18next from "i18next";
import en from './i18n/en'
import es from './i18n/es'
import { useChatState } from './core/stores/saga-chat.store'
import SagaChat from './core/components/organism/SagaChat/SagaChatBubble'

i18next.init({
  lng: "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en,
    es,
  },
});

const App: React.FC = () => {
  const chatState = useChatState((s) => s);

  return (
    <section className='app'>
      <I18nextProvider i18n={i18next}>
        <ChakraProvider theme={chakraTheme}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          {/* Modal de restricción de acceso */}
          <RestrictedActionModal />
          {/* Loader para peticiones http */}
          <Loader />
          {chatState.chatOpen && <SagaChat />}
        </ChakraProvider>
      </I18nextProvider>
    </section>
  )
}

export default App
