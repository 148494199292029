import { Box, Flex, Text, Button, Divider, Image, Tooltip, IconButton, AspectRatio } from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { RxDotsVertical } from 'react-icons/rx';
import { useState } from 'react';
import { useConciliationStore } from '../stores/conciliation.store';
import useMountedEffect from '@/core/hooks/useMountedEffect';
import { VaultService } from '@/core/services/vault.service';
import useCustomToast from '@/core/hooks/useCustomToast';
import { DataUtil } from '@/core/utils/data.util';

interface PlanningDetailCardProps {
  title?: string;
  status?: string;
  creationDate?: string;
}

const ConciliationDetailHeadCard = ({ title, status, creationDate }: PlanningDetailCardProps) => {
  const toast = useCustomToast();
  const vaultService = new VaultService()
  const selectedConciliation = useConciliationStore(e => e.selectedConciliation);
  const [imgSrc, setImgSrc] = useState<string>('');

  useMountedEffect(() => {
    if (selectedConciliation?.imageId) {
      vaultService.getFile(selectedConciliation?.imageId!).then(
        response => {
          //@ts-ignore
          setImgSrc(response.file)
        }
      ).catch((error) => {
        toast.error(error)
      })
    }
  })


  return (
    <>
      <Box p="1" pb={2}>
        <Flex pb={'2'} justify="space-between" align="center">
          <Flex columnGap={2}>
            <AspectRatio width={'50px'} ratio={4 / 3}>
              <Image borderRadius='md' src={imgSrc ? `data:image/${selectedConciliation?.imageExt};base64,${imgSrc}` : 'https://via.placeholder.com/150'} alt='Dan Abramov' />
            </AspectRatio>
            <Flex direction={'column'} gap={1}>
              <Flex align="center">
                <Text fontSize="xl" fontWeight="semibold" mr="2">
                  {selectedConciliation?.name}
                </Text>
              </Flex>
              <Flex gap={5}>
                <Text display={'flex'} alignItems={'center'} gap={1} fontSize="sm">
                  <FiUser />
                  <Text fontWeight="bold">Autor: </Text> {selectedConciliation?.userName}
                </Text>
                <Text display={'flex'} alignItems={'center'} gap={1} fontSize="sm">
                  <Text fontWeight="bold">Total de cartas de conciliación:</Text> {DataUtil.numberCurrencyFormat(parseFloat(selectedConciliation?.conciliationLetterAmount!))}
                </Text>
                <Text display={'flex'} alignItems={'center'} gap={1} fontSize="sm">
                  <Text fontWeight="bold">Total de órdenes de compra:</Text> {DataUtil.numberCurrencyFormat(parseFloat(selectedConciliation?.purchaseOrderAmount!))}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box>
            <Tooltip label='Opciones'>
              <Button
                as={IconButton}
                icon={<RxDotsVertical color='gray' size={20} />}
                variant="ghost"
                size="md"
                margin={'0'}
                padding={'6px'}
                borderRadius='full'
                onClick={(e) => console.log('open')}
              />
            </Tooltip>
          </Box>
        </Flex>
        <Divider borderColor="gray.300" />
      </Box>
    </>
  );
};

export default ConciliationDetailHeadCard;
