import CustomModal from "@/core/components/organism/CustomModal"
import FileDropzone from "@/core/components/organism/FileDropzone"
import { useModalStore } from "@/core/stores/modal.store"
import { useBoolean } from "@chakra-ui/react"
import { useFormik } from "formik"
import { FcUpload } from "react-icons/fc"
import { useState } from "react"
import { UtilService } from "@/core/services/util.service"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useConciliationStore } from "../stores/conciliation.store"

const utilService = new UtilService();

interface LoadProps {
  selectedFile?: File | null;
  setSelectedFile: (e: File | null) => void
}

const UploadConciliationLetterModal: React.FC<LoadProps> = ({ selectedFile, setSelectedFile }) => {
  const toast = useCustomToast();
  const closeModal = useModalStore(s => s.closeModal)
  const [hasErrorFile, setHasErrorFile] = useBoolean(false)
  const [file, setFile] = useState<FormData | undefined>(undefined)
  const { updateConciliationLetter } = useConciliationStore();

  const handleBudgetFile = () => {
    if (!file) {
      setHasErrorFile.on()
      return
    }

    setHasErrorFile.off()

    utilService.uploadFile(file!).then(
      response => {
        if (response.codigo === 0) {
          updateConciliationLetter(response.respuesta).then(() => {
            toast.success('Archivo cargado exitosamente')
            closeModal('UploadConciliationLetterModal')
          }).catch((error) => console.error(error))
        }
      }
    ).catch(
      () => {
        toast.error('Ocurrió un error al subir el archivo')
      }
    )
  }

  const loadFileForm = useFormik({
    onSubmit: handleBudgetFile,
    validateOnBlur: true,
    initialValues: {}
  })

  /* const handleFileUpload = (file: File) => {

    setFile(file);
  } */
  const handleFileUpload = (file: File) => {
    const formData = new FormData()
    //const extension = FilesUtil.extractFileExtension(file)

    formData.append("file", file)

    setFile(formData)

    setHasErrorFile.off()
  }

  return (
    <CustomModal id="UploadConciliationLetterModal"
      title="Cargar documento"
      size="xl"
      icon={FcUpload}
      showCancelButton={false}
      onAccept={loadFileForm.handleSubmit}
      closeOnAccept={false}
      acceptText="Cargar">
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FileDropzone accept="application/pdf" onDropFile={handleFileUpload} />
          {hasErrorFile && (
            <span className="text-red-500">
              El archivo es requerido
            </span>
          )}
        </form>
      )}
    </CustomModal>
  )
}

export default UploadConciliationLetterModal