import { Badge, Button, Flex, Select, Text } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { useConciliationStore } from '../stores/conciliation.store'
import { getColorBadgeV2 } from '@/modules/planning/hooks/useGetColorBadge'
import { BiCloudUpload } from 'react-icons/bi'
import { useModalStore } from '@/core/stores/modal.store'
import { DataUtil } from '@/core/utils/data.util'
import { CheckIcon } from '@chakra-ui/icons'
import ApprovePurchaseOrdersModal from './ApprovePurchaseOrdersModal'

interface SelectOrderProps {
  selectedOrder: string
  setSelectedOrder: (selectedOrder: string) => void
}

const PurchaseOrdersHeadVersionsStatus = ({ selectedOrder, setSelectedOrder }: SelectOrderProps) => {
  const openModal = useModalStore(e => e.openModal)
  const { selectedConciliation, selectedPurchaseOrder, setPurchaseOrder } = useConciliationStore()

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrder(e.target.value)
    const selectedVersion = selectedConciliation?.purchaseOrderList?.find(version => version.fileId === e.target.value)
    if (selectedVersion) {
      setPurchaseOrder(selectedVersion)
    }
  }

  return (
    <Flex justifyContent='space-between' alignItems='center' mb={3}>
      <Select
        placeholder='Selecciona una órden de compra'
        bg="white"
        borderRadius="md"
        w={'20%'}
        value={selectedOrder}
        onChange={handleChange}
      >
        {
          selectedConciliation?.purchaseOrderList?.map((e, i) => (
            <option key={e.fileId} value={e.fileId}>Órden de compra {i + 1} </option>
          ))
        }
      </Select>
      <Flex alignItems='center' gap={3}>
        <Text fontWeight='bold'>Estatus: </Text> <Badge rounded={10} colorScheme={getColorBadgeV2(selectedPurchaseOrder?.currentStatus)}>{selectedPurchaseOrder?.currentStatus}</Badge>
        <Text fontWeight='bold'>Monto: </Text> {selectedPurchaseOrder?.amount ? DataUtil.numberCurrencyFormat(selectedPurchaseOrder?.amount!) : 'Sin aprobar'}
      </Flex>
      <Flex gap={3}>
        <Button onClick={() => openModal('UploadPurchaseOrderModal')} colorScheme="brand" rightIcon={<BiCloudUpload />}>
          Cargar orden de compra
        </Button>
        {
          (selectedPurchaseOrder && selectedPurchaseOrder?.amount === 0) && <Button onClick={() => openModal('ApprovePurchaseOrdersModal')} colorScheme="brand" rightIcon={<CheckIcon />}>
            Aprobar
          </Button>
        }
      </Flex>
      <ApprovePurchaseOrdersModal />
    </Flex>
  )
}

export default PurchaseOrdersHeadVersionsStatus