import { Link, useLocation } from 'react-router-dom'
import logo from '@/assets/images/identity/logo.png'
import isotype from '@/assets/images/identity/isotype-om.svg'
import { memo, useEffect, useState } from 'react'
import Notifications from './Notifications'
import UserMenu from '@/core/components/molecules/UserMenu'
import Company from '@/core/components/atoms/Company'
import Navigation from '@/core/components/molecules/Navigation'
import { ExcludePathsNav } from '@/core/constants/exclude-paths-nav'
import saga_logo from '@/assets/images/saga/saga_logo.png'
import { useChatState } from '@/core/stores/saga-chat.store'

interface NavbarProps {
    userName: string
    companyName?: string
    signOut: () => void
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const chatState = useChatState((s) => s);
    /** */
    const location = useLocation()
    const [showNavigation, setShowNavigation] = useState(false)

    useEffect(() => {
        const excludeRoutesNavigation = ExcludePathsNav
        const isExcludeRouteNavigation = excludeRoutesNavigation.includes(location.pathname)

        setShowNavigation(!isExcludeRouteNavigation)
    }, [location, setShowNavigation])

    return (
        <header className="sticky top-0 z-40 flex items-center justify-between px-16 py-4 bg-white">
            <div className="flex">
                {/* Logo */}
                <Link to="/views">
                    <img className="md:block hidden h-[28px]" src={logo} alt="Logo Orkestamedia" />
                    <img className="md:hidden block h-[28px]" src={isotype} alt="Logo Orkestamedia" />
                </Link>
                {/* Navigation */}
                {showNavigation && (
                    <Navigation />
                )}
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                {/* Company info... */}
                <Company
                    name={props.companyName}
                />
                {/* Notifications */}
                <Notifications />
                {/* Handle User */}
                <div
                    style={{ cursor: 'pointer', backgroundImage: 'linear-gradient(160deg, #2347ad 0%, #0092f6 31%, #52c6ba 48%, #52c6ba 66%, #7678ed 90%, #7678ed 100%)', borderRadius: '50%', marginRight: '1%' }}
                    onClick={() => {
                        if (!chatState.chatOpen) {
                            chatState.changeChatState(true);
                        }
                    }}>
                    <img src={saga_logo} />
                </div>
                <UserMenu
                    userName={props.userName}
                    signOut={props.signOut}
                />
            </div>
        </header>
    )
}

export default memo(Navbar)