import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import { useComponentsStore } from '@/core/stores/components.store'
import { Button, Flex, Text } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useModalStore } from '@/core/stores/modal.store'
import { useConciliationStore } from '../stores/conciliation.store'
import WitnessesDocummentsViews from './WitnessesDocummentsView'
import { useState } from 'react'

function ConciliationBillingDocumentView() {
  const { uiConciliationComponents } = useComponentsStore()
  const { witnessDocumentData, selectedConciliation, selectedWitnessesPeriod, witnessEvidences } = useConciliationStore()
  const [currentIndex, setCurrentIndex] = useState<number>(witnessEvidences.findIndex(e => e.fileId === witnessDocumentData?.file));
  const openModal = useModalStore(e => e.openModal);
  return (
    <>
      <BrandPageHead subtitle={`${selectedWitnessesPeriod?.periodName} - ${selectedWitnessesPeriod?.mediaName} - ${selectedWitnessesPeriod?.providerName}`}>
        {(uiConciliationComponents.approveBilling && witnessEvidences[currentIndex]?.currentStatus === 'revision') ? <Button mb={2} mr={2} mt={2} rightIcon={<CheckIcon />} onClick={() => openModal('Billingapprove')}>Aprobar factura</Button> :
          <Flex gap={2} alignItems={'center'}>
            <Text fontWeight="bold">Campaña:</Text>
            <Text>{selectedConciliation?.name}</Text>
          </Flex>}
      </BrandPageHead>
      <WitnessesDocummentsViews currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} type='billing' />
    </>
  )
}

export default ConciliationBillingDocumentView