import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { useNavigationStore } from "@/core/stores/navigation.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useModalStore } from "@/core/stores/modal.store"
import { usePlanningStore } from "../store/planning.store"
import { UtilService } from "@/core/services/util.service"
import { useExcelTableStore } from "@/core/stores/excel-table.store"

const SaveExcelFileAlert: React.FC = () => {
  const utilService = new UtilService()
  const toast = useCustomToast()
  const sectionId = useNavigationStore(e => e.selectedNavigation)
  const closeModal = useModalStore(s => s.closeModal)
  const { retrievePlanningItems, selectedPlanning, createBudgetCardDocument } = usePlanningStore()
  const { selectedFile } = useExcelTableStore();


  const handleExcelFile = () => {
    const formData = new FormData()

    formData.append("file", selectedFile as File)

    utilService.uploadFile(formData!).then(
      response => {
        if (response.codigo === 0) {
          handleSaveExcelFile(response.respuesta)
        }
      }
    ).catch(
      () => {
        toast.error('Ocurrió un error al subir el documento')
      }
    )
  }

  const handleSaveExcelFile = (fileId: string) => {

    createBudgetCardDocument(selectedPlanning?.id!, fileId).then(
      () => {
        toast.success('Documento guardado correctamente')
        retrievePlanningItems(sectionId?.id!)
        closeModal('SaveExcelFileAlert')
      }
    ).catch(
      () => toast.error('Ocurrió un error al crear el Documento')
    )
  }

  return (
    <CustomAlertDialog
      id="SaveExcelFileAlert"
      title="Guardar cambios"
      cancelText="No"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleExcelFile}
    >
      {() => (
        <Box>
          <Text>¿Desea guardar los cambios realizados en el documento?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default SaveExcelFileAlert