import CommentsPanel from '@/modules/campaigns/components/CommentsPanel'
import { AspectRatio, Box, Button, Flex, Image } from '@chakra-ui/react'
import DocumentHead from '../../../core/components/molecules/DocumentHead'
import { useEffect, useState } from 'react'
import BillingapproveModal from './BillingApproveModal'
import { useConciliationStore } from '../stores/conciliation.store'
import { VaultService } from '@/core/services/vault.service'
import { Comment } from '@/modules/campaigns/models/campaign.model'
import { ConciliationItems } from '../models/conciliation.model'
import EvidenceApproveModal from './EvidenceApproveModal'


interface WitnessDocumentProps {
  currentIndex?: number
  setCurrentIndex?: (value: number) => void
  type?: string
}

const WitnessesDocummentsViews = ({ currentIndex, setCurrentIndex, type }: WitnessDocumentProps) => {
  const vaultService = new VaultService()
  const { witnessEvidences, selectedConciliation, updateWitnessComments, updateBillingComments } = useConciliationStore();

  const [imgSrc, setImgSrc] = useState<string>('')

  const handleComments = async (comments: Comment[]) => {
    const newItem = (type === 'witness' ? selectedConciliation?.witnessList : selectedConciliation?.billingList)?.map(e => e.fileId === witnessEvidences[currentIndex!]?.fileId ? {
      ...witnessEvidences[currentIndex!],
      comments: comments
    } : e)
    let body: ConciliationItems = {}
    if (type === 'witness') {
      body = {
        ...selectedConciliation,
        witnessList: newItem
      }
      updateWitnessComments(body).then(
        response => {
          console.log(response)
        }
      )
    } else {
      body = {
        ...selectedConciliation,
        billingList: newItem
      }
      updateBillingComments(body).then(
        response => {
          console.log(response)
        }
      )
    }

  }

  useEffect(() => {
    if (witnessEvidences[currentIndex!]?.fileId) {
      vaultService.getFile(witnessEvidences[currentIndex!]?.fileId!).then(
        response => {
          //@ts-ignore
          setImgSrc(response.file)
        }
      ).catch((error) => console.error(error))
    }
  }, [currentIndex])

  return (
    <Flex>
      <Box w={'70%'} textAlign='right' bg={'white'}>
        <DocumentHead status={witnessEvidences?.[currentIndex!]?.currentStatus} lenghtData={witnessEvidences.length} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} nameFile={witnessEvidences?.[currentIndex!]?.name} />
        <AspectRatio ratio={16 / 9}>
          {
            witnessEvidences[currentIndex!]?.fileExt === 'pdf' ?
              <object type="application/pdf" data={`data:application/pdf;base64,${imgSrc}`} width="100%" height="100%">
                <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={`data:application/pdf;base64,${imgSrc}`}>Descargar PDF</a>.</p>
              </object>
              :
              <Image src={`data:image/${witnessEvidences[currentIndex!]?.fileExt};base64,${imgSrc}`} objectFit='cover' />
          }
        </AspectRatio>
      </Box>
      <CommentsPanel comments={witnessEvidences[currentIndex!]?.comments} sendComments={handleComments} />
      <BillingapproveModal billing={witnessEvidences?.[currentIndex!]} />
      <EvidenceApproveModal witness={witnessEvidences?.[currentIndex!]} />
    </Flex>
  )
}

export default WitnessesDocummentsViews