import { Box, Button, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { RxDotsVertical } from 'react-icons/rx'
import { Comment } from '../models/campaign.model';
import { DatesUtil } from '@/core/utils/dates.util';

interface CommentBodyProps {
  item: Comment
}

function CommentBody({ item }: CommentBodyProps) {
  return (
    <Box borderBottom={'2px solid'} pb={2} display={'flex'} flexWrap={'wrap'} flexDirection={'column'} gap={1}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='sm' fontWeight={'bold'}>{item.userName}</Text>
        <Tooltip label='Opciones'>
          <Button
            as={IconButton}
            icon={<RxDotsVertical color='gray.600' size={20} />}
            variant="ghost"
            size="md"
            margin={'0'}
            padding={1}
            borderRadius='full'
            onClick={(e) => console.log('open')}
          />
        </Tooltip>
      </Flex>
      <Text fontSize='md' color="black">
        {item.comment}
      </Text>
      <Text fontSize={'sm'} color={'gray.400'}>
        {DatesUtil.getDateToComment(item.date!)}
      </Text>
    </Box>
  )
}

export default CommentBody