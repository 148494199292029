import { StateCreator, create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import GC from '@mescius/spread-sheets';
import { saveAs } from 'file-saver';

interface BudgetColsProps {
  name: string
  label: string
}

interface BudgetDataProps {
  name: string
  values: (string | number)[]
}

interface BudgetOrdererDataProps {
  [key: string]: string | number
}


/** Utilidades */
const budgetCols: BudgetColsProps[] = [
  { name: 'ZONA', label: 'zone' },
  { name: 'TIPO DE MEDIO', label: 'mediaType' },
  { name: 'RAZON SOCIAL', label: 'socialReason' },
  { name: 'TIPO DE PAGO', label: 'payType' },
  { name: 'MONTO TOTAL', label: 'total' }
]

const flowCols: BudgetColsProps[] = [
  { name: 'TIPO DE MEDIO', label: 'mediaType' },
  { name: 'PROVEEDOR', label: 'socialReason' },
  { name: 'MONTO TOTAL', label: 'total' }
]
const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));
const getFileType = (file: File): 'xlsx' | 'csv' | 'sjs' | 'ssjson' | 'unknown' => {
  const fileName = file.name.toLowerCase();
  if (fileName.endsWith('.xlsx')) return 'xlsx';
  if (fileName.endsWith('.csv')) return 'csv';
  if (fileName.endsWith('.sjs')) return 'sjs';
  if (fileName.endsWith('.ssjson')) return 'ssjson';
  return 'unknown';
};

const getColumnValues = (sheet: GC.Spread.Sheets.Worksheet, columnIndex: number, type?: number): string[] => {
  const rowCount: number = sheet.getRowCount();
  const values: string[] = [];

  for (let row = type ? (type + 1) : 1; row < rowCount; row++) { // Asumimos que la fila 0 es el encabezado
    const value = sheet.getValue(row, columnIndex);
    if (value !== null)
      values.push(value);
  }

  return values;
};

/** Contrato del Store */
interface FileStoreState {
  selectedFile: File | null
  openFileType: string
  openOptions: { [key: string]: any }
  fileTypeOptions: { [key: string]: any }
  spread: GC.Spread.Sheets.Workbook | null
  budgetData: BudgetOrdererDataProps[]
  setSpread: (spread: GC.Spread.Sheets.Workbook | null) => void
  setOpenOptions: (options: { [key: string]: any }) => void
  setSelectedFile: (file: File | null) => void
  setSelectedFileTable: (file: File) => void
  tempFunctionCols: () => void
  openBudgetFile: () => void
  openFlowFile: () => void
  openFile: () => void
  save: () => void
  mapExportFileType: () => void
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  selectedFile: null,
  openFileType: '',
  budgetData: [],
  openOptions: {
    sjs: {},
    ssjson: {},
    xlsx: {},
    csv: {},
  },
  spread: null,
  fileTypeOptions: {
    SJS: 'sjs',
    Excel: 'xlsx',
    SSJson: 'ssjson',
    Csv: 'csv',
  }
};

/** Definición del Store */
const Store: StateCreator<FileStoreState> = (set, get) => ({
  ...initialState,

  setSpread: (spread: GC.Spread.Sheets.Workbook | null) => set({ spread }),

  setOpenOptions: (options: { [key: string]: any }) => set({ openOptions: options }),

  setSelectedFile: (file: File | null) => {
    const fileType = file !== null ? getFileType(file) : '';
    set({ selectedFile: file, openFileType: fileType });
  },



  openFlowFile: () => {
    const { openFile, spread } = get();
    openFile()
    if (!spread) return

    setTimeout(() => {
      const sheet = spread.sheets[0]
      const sheetCounts = spread.sheets[0].getColumnCount()
      let budget: BudgetDataProps[] = []
      const rows = sheet.getRowCount()
      let rowValid = 0
      for (let row = 0; row < rows; row++) {
        const cellValue = sheet.getCell(row, 0).text();
        if (cellValue !== '' && cellValue !== null) {
          rowValid = row
          break
        }
      }
      for (let col = 0; col < sheetCounts; col++) {
        const cellValue = sheet.getCell(rowValid, col).text(); // Primera fila, columna 'col'
        flowCols.map((e) => {
          if (cellValue.includes(e.name)) {
            const values = getColumnValues(sheet, col, rowValid);
            budget.push({
              name: e.label,
              values: values
            })
          }
        })
      }
      const dataRows = budget[0].values.map((e, i) => {
        return budget.reduce((acc, currentArray) => {
          acc[currentArray.name] = currentArray.values[i];
          return acc;
        }, {} as BudgetOrdererDataProps);
      })
      set({ budgetData: dataRows })
    }, 1000)
  },

  openBudgetFile: () => {
    const { openFile, spread } = get();
    openFile()
    if (!spread) return

    setTimeout(() => {
      const sheet = spread.sheets[0]
      const sheetCounts = spread.sheets[0].getColumnCount()
      let budget: BudgetDataProps[] = []
      for (let col = 0; col < sheetCounts; col++) {
        const cellValue = sheet.getCell(0, col).text(); // Primera fila, columna 'col'
        budgetCols.map((e) => {
          if (cellValue.includes(e.name)) {
            const values = getColumnValues(sheet, col);
            budget.push({
              name: e.label,
              values: values
            })
          }
        })
      }
      const dataRows = budget[0].values.map((e, i) => {
        return budget.reduce((acc, currentArray) => {
          acc[currentArray.name] = currentArray.values[i];
          return acc;
        }, {} as BudgetOrdererDataProps);
      })
      set({ budgetData: dataRows })
    }, 500)
  },

  openFile: () => {
    const { selectedFile, spread, openOptions } = get();

    if (!spread || !selectedFile) {
      return;
    }

    const fileType = getFileType(selectedFile);
    // @ts-ignore
    const options = deepClone(openOptions[fileType]);


    spread.import(selectedFile, () => { }, () => { }, options);
  },

  tempFunctionCols: () => {
    const { selectedFile, spread, openOptions } = get();

    if (!spread || !selectedFile) {
      return;
    }

    const fileType = getFileType(selectedFile);
    // @ts-ignore
    const options = deepClone(openOptions[fileType]);


    return options
  },

  setSelectedFileTable: (file: File) => {
    const { spread, openOptions, setSelectedFile } = get();
    const fileType = getFileType(file);
    //@ts-ignore
    setSelectedFile(file)
    if (!spread || !fileType) {
      return;
    }
    const options = deepClone(openOptions[fileType]);
    spread.import(file, () => { }, () => { }, options);
  },

  mapExportFileType: () => {
    const { selectedFile, fileTypeOptions, spread } = get();
    if (!spread || !selectedFile) {
      return;
    }
    var fileType = getFileType(selectedFile);
    if (fileType === fileTypeOptions.Csv) {
      return GC.Spread.Sheets.FileType.csv;
    }
    return GC.Spread.Sheets.FileType.excel;
  },

  save: () => {
    const { selectedFile, spread, openOptions, mapExportFileType } = get();
    /* if (!spread || !selectedFile) {
      return;
    } */
    var fileType = selectedFile ? getFileType(selectedFile) : 'xlsx';
    var fileName = `${selectedFile ? selectedFile.name : 'presupuesto'}.${fileType}`;
    const options = deepClone(openOptions[fileType]);
    options.fileType = mapExportFileType();
    spread!.export((blob: Blob) => {
      saveAs(blob, fileName);
    }, function () { }, options);
  },
  reset: () => set(initialState)
});

/** Exportación del Store */
export const useExcelTableStore = create<FileStoreState>()(
  immer(Store),
);