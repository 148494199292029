import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { useConciliationStore } from '../stores/conciliation.store'
import { ConciliationBilling, FileReviewable } from '../models/conciliation.model'
import { CurrentStatus } from '@/modules/campaigns/enums/campaign.enum'
import useCustomToast from '@/core/hooks/useCustomToast'

interface BillingProps {
  billing: FileReviewable
}

const BillingapproveModal = ({ billing }: BillingProps) => {
  const toast = useCustomToast()
  const approveRequirements = ['Orden de compra cerrada', 'Factura', 'Carta de conciliación', 'Testigos', 'Información fiscal']
  const { updateConciliationBillingStatus, selectedConciliation } = useConciliationStore();
  const handleApproveEvidence = async () => {
    await updateConciliationBillingStatus(selectedConciliation?.id!, billing.fileId, CurrentStatus.aprobado).then(e => {
      toast.success('Factura aprobada con éxito')
    }).catch((error) => {
      toast.success('Error al aprobar factura')
      console.error(error)
    })
  }

  return (
    <CustomAlertDialog
      id="Billingapprove"
      title={`Aprobar factura`}
      cancelText="Cancelar"
      acceptText="Aprobar factura"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleApproveEvidence}
    >
      {() => (
        <Box>
          <Text mb={1}>La factura del proveedor se validó correctamente</Text>
          {
            approveRequirements.map((e, index) => (
              <Flex key={index} color='green' alignItems='center' columnGap={1} mb={1}>
                <IoMdCheckmarkCircleOutline /><Text>{e}</Text>
              </Flex>
            ))
          }
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default BillingapproveModal