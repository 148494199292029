import { Badge, Flex, IconButton, Text } from '@chakra-ui/react'
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

interface DocumentHeadProps {
  nameFile?: string
  setCurrentIndex?: (index: number) => void
  lenghtData?: number
  currentIndex?: number
  status?: string
}
function DocumentHead({ nameFile, setCurrentIndex, lenghtData, currentIndex, status }: DocumentHeadProps) {

  return (
    <Flex justifyContent='space-between' p={3}>
      <Flex gap={2} alignItems={'center'}>
        <Text fontWeight="bold">{nameFile}</Text>
      </Flex>
      <Flex alignItems='center' columnGap={3}>
        {status &&
          <Flex alignItems='center' gap={1}>
            <Text fontWeight="bold">Estatus:</Text>
            <Badge colorScheme={status === 'revision' ? 'yellow' : 'green'} rounded='full' paddingX={3}>
              {status}
            </Badge>
          </Flex>
        }
        <IconButton
          aria-label="Prev"
          icon={<IoMdArrowBack />}
          colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
          variant="outline"
          onClick={() => setCurrentIndex?.(currentIndex! - 1)} // Agrega la función de retroceso
          isDisabled={currentIndex === 0}
        />
        <IconButton
          aria-label="Next"
          icon={<IoMdArrowForward />}
          colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
          variant="outline"
          onClick={() => setCurrentIndex?.(currentIndex! + 1)} // Agrega la función de retroceso
          isDisabled={currentIndex === (lenghtData! - 1)}
        />
      </Flex>
    </Flex>
  )
}

export default DocumentHead